import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { setUserSession } from "../../utils/Common";
import { Image, Transformation } from "cloudinary-react";
import Select from "react-select";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Create() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  // Session
  const session = localStorage.getItem("user");
  const uid = JSON.parse(session).id;
  const udept = JSON.parse(session).nm_department;
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  // Header Var
  const [forDept, setForDept] = useState(udeptID);
  const [forHotel, setForHotel] = useState(null);
  const [dateNeed, setDateNeed] = useState();
  const [ppStatus, setPPStatus] = useState();
  const [ppRemark, setPPRemark] = useState();
  const [submitting, setSubmitting] = useState(false);

  const initialFormStateItem = [
    {
      id: null,
      item_id: "",
      quantity: "",
      min_order: "",
      img_item: "",
      desc_item: "",
      unit: "",
      vol_item: "",
      group_item: "",
      lead_time: "",
      urlItem: "",
      commentItem: "",
    },
  ];

  const onSelectItemChange = (e, i) => {
    const list = [...ppItemList];
    list[i][`item_id`] = e.value;
    list[i][`quantity`] = e.min_order;
    list[i][`min_order`] = e.min_order;
    list[i][`img_item`] = e.img_item;
    list[i][`desc_item`] = e.desc_item;
    list[i][`unit`] = e.unit;
    list[i][`vol_item`] = e.vol_item;
    list[i][`group_item`] = e.group_item;
    list[i][`lead_time`] = e.lead_time;
    setPPItemList(list);
  };

  // Handle click event of the Add Record
  const handleAddClick = () => {
    setPPItemList([
      ...ppItemList,
      {
        id: null,
        item_id: "",
        quantity: "",
        min_order: "",
        img_item: "",
        desc_item: "",
        unit: "",
        vol_item: "",
        group_item: "",
        lead_time: "",
        urlItem: "",
        commentItem: "",
      },
    ]);
  };

  // Handle click event of the Remove Record
  const handleRemoveClick = (index) => {
    const list = [...ppItemList];
    list.splice(index, 1);
    setPPItemList(list);
  };

  const [ppItemList, setPPItemList] = useState(initialFormStateItem);
  const [itemList, setItemList] = useState([]);

  const selectHotel = async (e) => {
    var forHot = e;
    setForHotel(e);
    try {
      const response = await axios.post(
        `/api/list/items/pp`,
        { forDept, forHot },
        { headers: headers }
      );
      setItemList(Object.values(response.data).flatMap((arr) => arr));
    } catch (error) {
      console.log(error);
    }
  };

  const [hotelList, setHotelList] = useState([]);

  const getListHotelbyUser = async () => {
    try {
      const response = await axios.post(
        `/api/list/hotel/by-user`,
        { hcode },
        { headers: headers }
      );
      setHotelList(response.data);
    } catch (error) {
      console.log("Error API Hotel: ", error);
    } finally {
      console.log("done");
    }
  };

  // Handle Add Item Freq
  const handleInputQtyItem = (e, index) => {
    const list = [...ppItemList];
    list[index]["quantity"] = e.target.value;
    setPPItemList(list);
  };

  const handleURLItem = (e, index) => {
    const list = [...ppItemList];
    list[index]["urlItem"] = e.target.value;
    setPPItemList(list);
  };

  const handleCommentItem = (e, index) => {
    const list = [...ppItemList];
    list[index]["commentItem"] = e.target.value;
    setPPItemList(list);
  };

  const handleSubmitPP = async () => {
    if (
      !dateNeed ||
      !forHotel ||
      !ppStatus ||
      !ppRemark ||
      !ppItemList[0]["item_id"] ||
      !ppItemList[0]["quantity"] ||
      ppItemList[0]["quantity"] === 0 ||
      ppItemList[0]["quantity"] < ppItemList[0]["min_order"]
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      const result = await Swal.fire({
        title: "Do you want to upload the proposal?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      });

      if (result.isConfirmed) {
        setSubmitting(true);
        try {
          const response = await axios.post(
            `/api/insert/purchase/proposal`,
            {
              dateNeed,
              forHotel,
              forDept,
              ppStatus,
              ppRemark,
              uid,
              ppItemList,
            },
            { headers: headers }
          );
          history.push("/waiting/proposal");
        } catch (error) {
          console.log(error);
        } finally {
          setSubmitting(false);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    }
  };

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);

  useEffect(() => {
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" class="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Create New
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                New
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                {" "}
                Proposal
              </li>
            </ol>
          </nav>
        </div>

        <section>
          {/* HEADER */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div id="headerPP" className="row pt-3">
                    <div class="col-sm-12 col-md-4 pb-3">
                      <label htmlFor="dateNeed" class="form-label">
                        *Date Need
                      </label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        id="dateNeed"
                        onChange={(e) => setDateNeed(e.target.value)}
                        min={new Date().toISOString().slice(0, 10)}
                      />
                    </div>

                    <div class="col-sm-12 col-md-4 pb-3">
                      <label htmlFor="forHotel" class="form-label">
                        *For Hotel
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="forHotel"
                        onChange={(e) => selectHotel(e.target.value)}
                      >
                        <option>Select Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div class="col-sm-12 col-md-4 pb-3">
                      <label htmlFor="status" class="form-label">
                        *Status
                      </label>

                      <select
                        class="form-control form-control-sm"
                        id="status"
                        onChange={(e) => setPPStatus(e.target.value)}
                      >
                        <option>Status</option>
                        <option value="Regular">Regular</option>
                        <option value="Urgent">Urgent</option>
                      </select>
                    </div>

                    <div class="col-md-12">
                      <label htmlFor="explanation" class="form-label">
                        Purchase Explanation
                      </label>
                      <textarea
                        class="form-control form-control-sm"
                        id="explanation"
                        style={{ height: "100px" }}
                        onChange={(e) => setPPRemark(e.target.value)}
                      ></textarea>
                    </div>

                    {ppItemList[0]["item_id"] && (
                      <div className="col-12 mt-3">
                        <button
                          className="btn btn-block"
                          style={{
                            fontSize: "12px",
                            backgroundColor: "#ffce54",
                          }}
                          onClick={() => handleSubmitPP()}
                        >
                          {submitting ? "Submitting.." : "Submit Proposal"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {ppItemList.map((x, i) => (
            <div className="row" key={i} id="ITEMPP">
              <div className="col-12">
                <div className="card">
                  <div className="card-body pt-3">
                    <div className="row pt-3">
                      <div class="col-sm-12 col-md-12 pb-3">
                        <Select
                          options={itemList}
                          getOptionLabel={(itemList) => `${itemList.label}`}
                          id="item_id"
                          placeholder="*Select Item"
                          onChange={(e) => onSelectItemChange(e, i)}
                        />
                      </div>
                      <div class="col-sm-12 col-md-4 pb-3">
                        <label htmlFor="quantity" class="form-label">
                          *Quantity (Min Order : {ppItemList[i].min_order} )
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={ppItemList[i].min_order || 0}
                          onKeyDown={(e) => {
                            if (e.key.length === 1 && /\D/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          id="quantity"
                          onChange={(e) => handleInputQtyItem(e, i)}
                          defaultValue={ppItemList[i].min_order}
                        />
                      </div>

                      <div class="col-sm-12 col-md-4 pb-3">
                        <label htmlFor="urlitem" class="form-label">
                          *URL Recommend Store
                        </label>
                        <input
                          type="url"
                          id="urlitem"
                          className="form-control form-control-sm"
                          onChange={(e) => handleURLItem(e, i)}
                        ></input>
                      </div>

                      <div class="col-sm-12 col-md-4 pb-3">
                        <label htmlFor="descitem" class="form-label">
                          *Desc Item for
                        </label>
                        <input
                          type="text"
                          id="descitem"
                          className="form-control form-control-sm"
                          onChange={(e) => handleCommentItem(e, i)}
                        ></input>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col-sm-12 col-md-6 pb-3">
                        <p>Desc : {ppItemList[i].desc_item}</p>
                        <p style={{ marginTop: "-1%" }}>
                          Group : {ppItemList[i].group_item} Day
                        </p>
                        <p style={{ marginTop: "-1%" }}>
                          Min Order : {ppItemList[i].min_order} /{" "}
                          {ppItemList[i].unit}
                        </p>
                        <p style={{ marginTop: "-1%" }}>
                          Lead Time Est : {ppItemList[i].lead_time} Day
                        </p>
                      </div>
                      <div class="col-sm-12 col-md-6 pb-3 float-right">
                        <Image
                          style={{
                            width: "200px",
                            height: "180px",
                            marginBottom: "10px",
                          }}
                          cloudName="cloud-prima-hotel-indonesia"
                          publicId={
                            ppItemList[i].img_item ||
                            "https://res.cloudinary.com/cloud-prima-hotel-indonesia/image/upload/v1683824471/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl_qlyqb1.jpg"
                          }
                        >
                          <Transformation overlay="phi_png" />
                          <Transformation width="500" crop="scale" />
                          <Transformation opacity="20" />
                          <Transformation
                            flags="layer_apply"
                            gravity="center"
                          />
                        </Image>
                      </div>
                    </div>

                    <div className="col-12 mb-2 ml-2">
                      <div
                        style={{
                          margin: "1%",
                          borderBottom: "1px solid #e8e8e8",
                        }}
                      >
                        <div className="form-group form-group-custom">
                          {ppItemList.length !== 1 && (
                            <button
                              className="btn btn-danger btn-rounded btn-sm mb-3"
                              onClick={() => handleRemoveClick(i)}
                              style={{ fontSize: "12px" }}
                            >
                              <i class="bi bi-eraser-fill"></i> Remove
                            </button>
                          )}
                          {ppItemList.length - 1 === i && (
                            <button
                              className="btn btn-info btn-rounded btn-sm mb-3 mt-1 float-end"
                              onClick={handleAddClick}
                              style={{ fontSize: "12px" }}
                            >
                              <i class="bi bi-bag-plus-fill"></i> More Item
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </main>
    </>
  );
}

export default Create;
