import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Select from "react-select";
import Moment from "moment";
import { setUserSession } from "../../utils/Common";
import { getToken } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import { Image, Transformation } from "cloudinary-react";
import _ from "lodash";

function EditItem() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const params = useParams();
  const ITEMID = params.IDITEM;

  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  const [hotelList, setHotelList] = useState([]);
  const [itemHotel, setItemHotel] = useState([]);
  const [itemUnit, setItemUnit] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [IMGUpload, setIMGUpload] = useState(null);
  const [itemName, setItemName] = useState();
  const [itemDescription, setItemDescription] = useState();
  const [itemGroupSelect, setItemGroupSelect] = useState();
  const [itemUnitSelect, setItemUnitSelect] = useState();
  const [itemVolume, setItemVolume] = useState();
  const [itemLeadTime, setItemLeadTime] = useState();
  const [itemMinOrder, setItemMinOrder] = useState();
  const [itemHotelSelect, setItemHotelSelect] = useState();
  const [updating, setUpdating] = useState(false);

  const uploadImage = (e) => {
    console.log(e);
    let file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "hflkjxlr");
    formData.append("cloud_name", "cloud-prima-hotel-indonesia");
    fetch(
      "https://api.cloudinary.com/v1_1/cloud-prima-hotel-indonesia/image/upload",
      {
        method: "post",
        body: formData,
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data.url);
        setIMGUpload(data.url);
      });
  };

  const handleChangeItemHotel = (e) => {
    setItemHotel(e);
  };

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotels/for/select/user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(Object.values(response.data).flatMap((arr) => arr));
      })
      .catch(function (error) {
        console.log("Error API Hotel");
      });
  }, [hcode, headers]);

  const getItemUnit = useCallback(() => {
    axios
      .get(`/api/list/item/unit`, { headers: headers })
      .then(function (response) {
        setItemUnit(response.data);
      })
      .catch(function (error) {
        console.log("Error API Item Unit");
      });
  }, [headers]);

  const getItemGroup = useCallback(() => {
    axios
      .get(`/api/list/item/group`, { headers: headers })
      .then(function (response) {
        setItemGroup(response.data);
      })
      .catch(function (error) {
        console.log("Error API Item Unit");
      });
  }, [headers]);

  const UpdateItem = async () => {
    if (
      !itemName ||
      !itemUnit ||
      !itemGroupSelect ||
      !itemLeadTime ||
      !itemVolume ||
      !itemUnitSelect ||
      !itemMinOrder ||
      !IMGUpload ||
      !itemHotel
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      const result = await Swal.fire({
        title: "Do you want to update the item?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      });

      if (result.isConfirmed) {
        setUpdating(true);
        try {
          const response = await axios.post(
            `/api/update/item`,
            {
              ITEMID,
              udeptID,
              itemName,
              itemUnitSelect,
              itemGroupSelect,
              itemLeadTime,
              itemDescription,
              itemVolume,
              itemMinOrder,
              IMGUpload,
              itemHotel,
            },
            { headers: headers }
          );

          console.log(response.data);
          history.push("/list/item");
        } catch (error) {
          console.log(error);
        } finally {
          setUpdating(false);
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    }
  };

  const getDetailItem = () => {
    axios
      .get(`/api/detail/item/${ITEMID}`, { headers: headers })
      .then(function (response) {
        console.log(response.data);
        setItemName(response.data.nama_item);
        setItemDescription(response.data.desc_item);
        setItemGroupSelect(response.data.group_item);
        setItemLeadTime(response.data.lead_time);
        setItemUnitSelect(response.data.unit);
        setItemVolume(response.data.vol_item);
        setItemMinOrder(response.data.min_order);
        setIMGUpload(response.data.img_item);
        setItemHotelSelect(response.data.nm_hotel);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const debouncedGetDetailItem = _.debounce(getDetailItem, 200);
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);
  const debouncedGetItemUnit = _.debounce(getItemUnit, 200);
  const debouncedGetItemGroup = _.debounce(getItemGroup, 200);

  // useEffect to use the debounced functions
  useEffect(() => {
    debouncedGetDetailItem();
    debouncedGetListHotelbyUser();
    debouncedGetItemUnit();
    debouncedGetItemGroup();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Item Detail</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item ">Item</li>
              <li className="breadcrumb-item active">Detail</li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row pt-3">
                    <div class="col-md-6 col-sm-12">
                      <label htmlFor="itemName" class="form-label">
                        Item Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="itemName"
                        defaultValue={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                      ></input>
                    </div>

                    <div class="col-md-3 col-sm-12">
                      <label htmlFor="itemImage" class="form-label">
                        Item Image
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        onChange={(e) => uploadImage(e)}
                        id="itemImage"
                      ></input>
                    </div>

                    <div class="col-md-3 col-sm-12 text-center">
                      {selectedImage ? (
                        <img className="img-fluid" src={selectedImage} />
                      ) : (
                        <Image
                          style={{ width: "120px" }}
                          cloudName="cloud-prima-hotel-indonesia"
                          publicId={IMGUpload}
                        >
                          <Transformation overlay="phi_png" />
                          <Transformation width="500" crop="scale" />
                          <Transformation opacity="20" />
                          <Transformation
                            flags="layer_apply"
                            gravity="center"
                          />
                        </Image>
                      )}
                    </div>

                    <div class="col-md-12 mt-2">
                      <label htmlFor="itemDesc" class="form-label">
                        Item Explanation
                      </label>
                      <textarea
                        class="form-control"
                        id="itemDesc"
                        style={{ height: "100px" }}
                        defaultValue={itemDescription}
                        onChange={(e) => setItemDescription(e.target.value)}
                      ></textarea>
                    </div>

                    <div class="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="unitItem" class="form-label">
                        Item Unit
                      </label>
                      <select
                        class="form-control"
                        id="unitItem"
                        onChange={(e) => setItemUnitSelect(e.target.value)}
                      >
                        <option value={itemUnitSelect} selected>
                          {itemUnitSelect}{" "}
                        </option>
                        {itemUnit.map((x, t) => (
                          <option key={t} value={x.measurement}>
                            {x.measurement}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="groupItem" class="form-label">
                        Item Group
                      </label>
                      <select
                        class="form-control"
                        id="groupItem"
                        onChange={(e) => setItemGroupSelect(e.target.value)}
                      >
                        <option value={itemGroupSelect} selected>
                          {itemGroupSelect}
                        </option>
                        {itemGroup.map((x, t) => (
                          <option key={t} value={x.category}>
                            {x.category}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="itemTime" class="form-label">
                        Estimate lead Time
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="itemTime"
                        defaultValue={itemLeadTime}
                        onChange={(e) => setItemLeadTime(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="itemVolume" class="form-label">
                        Item Volume
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="itemVolume"
                        defaultValue={itemVolume}
                        onChange={(e) => setItemVolume(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="minOrder" class="form-label">
                        Min Order
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="minOrder"
                        defaultValue={itemMinOrder}
                        onChange={(e) => setItemMinOrder(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-12 col-sm-12 mt-2 mb-2">
                      <label htmlFor="forHotel" class="form-label">
                        Please Select Hotel
                      </label>
                      <Select
                        placeholder={itemHotelSelect}
                        id="forHotel"
                        isMulti={true}
                        onChange={(e) => handleChangeItemHotel(e)}
                        options={hotelList}
                      />
                    </div>

                    {itemName && itemUnit && itemHotel && (
                      <div className="p-3">
                        <button
                          className="btn btn-success mb-5"
                          onClick={() => UpdateItem()}
                        >
                          {updating ? "Updating.." : "Update & Approve"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditItem;
