import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { getToken } from "../../utils/Common";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Edit() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  const params = useParams();
  const SUPPID = params.IDSUPP;
  const [detailSupplier, setDetailSupplier] = useState([]);

  const [companyName, setCompanyName] = useState();
  const [companyType, setCompanyType] = useState();
  const [companyPhone, setCompanyPhone] = useState();
  const [companyEmail, setCompanyEmail] = useState();
  const [companyAddress, setCompanyAddress] = useState();
  const [warehouseAddress, setWarehouseAddress] = useState();
  const [manufactureAddress, setManufactureAddress] = useState();
  const [companyBussines, setCompanyBussines] = useState();
  const [productCategory, setProductCategory] = useState();
  const [goodSource, setGoodSource] = useState();
  const [companyRep, setCompanyRep] = useState();
  const [companyRepName, setCompanyRepName] = useState();
  const [telpRep, setTelpRep] = useState();
  const [companyNPWP, setCompanyNPWP] = useState();
  const [companyStatus, setCompanyStatus] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentTerm, setPaymentTerm] = useState();
  const [bankName, setBankName] = useState();
  const [bankAccountNo, setBankAccountNo] = useState();
  const [bankAccountName, setBankAccountName] = useState();
  const [bankAddress, setBankAddress] = useState();

  const [updating, setUpdating] = useState(false);

  const UpdateSupplier = async () => {
    setUpdating(true);
    try {
      const response = await axios.post(
        `/api/update/supplier`,
        { detailSupplier },
        { headers: headers }
      );
      Swal.fire({
        icon: "success",
        text: "Data has been updated",
      });
    } catch (error) {
      console.log("Error getAPIListPP: ", error);
    } finally {
      setUpdating(false);
    }
  };

  const getDetailSupplier = () => {
    axios
      .get(`/api/detail/supplier/${SUPPID}`, { headers: headers })
      .then(function (response) {
        // console.log(response.data)
        setDetailSupplier(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPIListPP: ", error);
      });
  };

  const debouncedGetDetailSupplier = _.debounce(getDetailSupplier, 100);

  // Your useEffect hook
  useEffect(() => {
    // Call the debounced function
    debouncedGetDetailSupplier();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Edit Supplier & Vendor
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Edit
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Supplier
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-5">
                    <div class="col-md-12 mb-2">
                      <label htmlFor="companyName" class="form-label">
                        Company Name*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyName"
                        defaultValue={detailSupplier.companyname}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["companyname"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4">
                      <label htmlFor="companyType" class="form-label">
                        Company Type*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="companyType"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["companytype"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.companytype}>
                          {detailSupplier.companytype}
                        </option>
                        <option value="PT">PT</option>
                        <option value="UD">UD</option>
                        <option value="CV">CV</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyPhone" class="form-label">
                        Phone*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        defaultValue={detailSupplier.telephoneno}
                        id="companyPhone"
                        maxLength={13}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["telephoneno"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyEmail" class="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="companyEmail"
                        defaultValue={detailSupplier.email}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["email"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-md-12 mb-2">
                      <label htmlFor="companyAddress" class="form-label">
                        Company Address*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyAddress"
                        defaultValue={detailSupplier.officeaddress}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["officeaddress"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-md-12 mb-2">
                      <label htmlFor="warehouseAddress" class="form-label">
                        Warehouse Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="warehouseAddress"
                        defaultValue={detailSupplier.warehouseaddress}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["warehouseaddress"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-md-12 mb-5">
                      <label htmlFor="manufactureAddress" class="form-label">
                        Manufacture Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="manufactureAddress"
                        defaultValue={detailSupplier.manufactureaddress}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["manufactureaddress"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyBussines" class="form-label">
                        Main Bussines*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="companyBussines"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["mainbusiness"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.mainbusiness}>
                          {detailSupplier.mainbusiness}
                        </option>
                        <option value="Automotive">Automotive </option>
                        <option value="Business Support & Supplies">
                          Business Support & Supplies{" "}
                        </option>
                        <option value="Computers & Electronics">
                          Computers & Electronics{" "}
                        </option>
                        <option value="Construction & Contractors">
                          Construction & Contractors{" "}
                        </option>
                        <option value="Education">Education </option>
                        <option value="Entertainment">Entertainment </option>
                        <option value="Food & Dining">Food & Dining </option>
                        <option value="Health & Medicine">
                          Health & Medicine{" "}
                        </option>
                        <option value="Home & Garden">Home & Garden </option>
                        <option value="Legal & Financial">
                          Legal & Financial{" "}
                        </option>
                        <option value="Manufacturing, Wholesale,Distribution">
                          Manufacturing, Wholesale,Distribution{" "}
                        </option>
                        <option value="Merchants (Retail)">
                          Merchants (Retail){" "}
                        </option>
                        <option value="Miscellaneous">Miscellaneous </option>
                        <option value="Personal Care & Services">
                          Personal Care & Services{" "}
                        </option>
                        <option value="Real Estate">Real Estate </option>
                        <option value="Travel & Transportation ">
                          Travel & Transportation{" "}
                        </option>
                        <option value="etc">etc </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="productCategory" class="form-label">
                        Product Category*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="productCategory"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["productcategory"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.productcategory}>
                          {detailSupplier.productcategory}
                        </option>
                        <option value="Automotive">Automotive </option>
                        <option value="Auto Accessories">
                          Auto Accessories
                        </option>
                        <option value="Auto Dealers-New">
                          Auto Dealers - New
                        </option>
                        <option value="Auto Dealers-Used">
                          Auto Dealers - Used
                        </option>
                        <option value="Detail & Carwash">
                          Detail & Carwash
                        </option>
                        <option value="Gas Stations">Gas Stations</option>
                        <option value="Motorcycle Sales & Repair">
                          Motorcycle Sales & Repair
                        </option>
                        <option value="Rental & Leasing">
                          Rental & Leasing
                        </option>
                        <option value="Service, Repair & Parts">
                          Service, Repair & Parts
                        </option>
                        <option value="Towing">Towing </option>
                        <option value="Consultants">Consultants</option>
                        <option value="Employment Agency">
                          Employment Agency
                        </option>
                        <option value="Marketing & Communications">
                          Marketing & Communications
                        </option>
                        <option value="Office Supplies">Office Supplies</option>
                        <option value="Printing & Publishing">
                          Printing & Publishing
                        </option>
                        <option value="Computer Programming & Support">
                          Computer Programming & Support
                        </option>
                        <option value="Consumer Electronics & Accessories">
                          Consumer Electronics & Accessories
                        </option>
                        <option value="Architects, Landscape Architects, Engineers & Surveyors">
                          Architects, Landscape Architects, Engineers &
                          Surveyors
                        </option>
                        <option value="Blasting & Demolition">
                          Blasting & Demolition
                        </option>
                        <option value="Building Materials & Supplies">
                          Building Materials & Supplies
                        </option>
                        <option value="Construction Companies">
                          Construction Companies
                        </option>
                        <option value="Electricians">Electricians</option>
                        <option value="Engineer, Survey">
                          Engineer, Survey
                        </option>
                        <option value="Environmental Assessments">
                          Environmental Assessments
                        </option>
                        <option value="Inspectors">Inspectors</option>
                        <option value="Plaster & Concrete">
                          Plaster & Concrete
                        </option>
                        <option value="Plumbers">Plumbers</option>
                        <option value="Roofers">Roofers</option>
                        <option value="Adult & Continuing Education">
                          Adult & Continuing Education
                        </option>
                        <option value="Early Childhood Education">
                          Early Childhood Education
                        </option>
                        <option value="Educational Resources">
                          Educational Resources
                        </option>
                        <option value="Other Educational">
                          Other Educational
                        </option>
                        <option value="Artists, Writers">
                          Artists, Writers
                        </option>
                        <option value="Event Planners & Supplies">
                          Event Planners & Supplies
                        </option>
                        <option value="Golf Courses">Golf Courses</option>
                        <option value="Movies">Movies</option>
                        <option value="Productions">Productions</option>
                        <option value="Desserts, Catering & Supplies">
                          Desserts, Catering & Supplies
                        </option>
                        <option value="Fast Food & Carry Out">
                          Fast Food & Carry Out
                        </option>
                        <option value="Grocery, Beverage & Tobacco">
                          Grocery, Beverage & Tobacco
                        </option>
                        <option value="Restaurants">Restaurants</option>
                        <option value="Acupuncture">Acupuncture</option>
                        <option value="Assisted Living & Home Health Care">
                          Assisted Living & Home Health Care
                        </option>
                        <option value="Audiologist">Audiologist</option>
                        <option value="Chiropractic">Chiropractic</option>
                        <option value="Clinics & Medical Centers">
                          Clinics & Medical Centers
                        </option>
                        <option value="Dental">Dental</option>
                        <option value="Diet I& Nutrition">
                          Diet I& Nutrition
                        </option>
                        <option value="Antiques & Collectibles">
                          Antiques & Collectibles
                        </option>
                        <option value="Cleaning">Cleaning</option>
                        <option value="Crafts, Hobbies & Sports">
                          Crafts, Hobbies & Sports
                        </option>
                        <option value="Flower Shops">Flower Shops</option>
                        <option value="Home Furnishings">
                          Home Furnishings
                        </option>
                        <option value="Home Goods">Home Goods</option>
                        <option value="Home Improvements & Repairs">
                          Home Improvements & Repairs
                        </option>
                        <option value="Landscape & Lawn Service">
                          Landscape & Lawn Service
                        </option>
                        <option value="Pest Control">Pest Control</option>
                        <option value="Pool Supplies & Service">
                          Pool Supplies & Service
                        </option>
                        <option value="Security System & Services">
                          Security System & Services
                        </option>
                        <option value="Accountants">Accountants</option>
                        <option value="Attorneys">Attorneys</option>
                        <option value="Financial Institutions">
                          Financial Institutions
                        </option>
                        <option value="Financial Services">
                          Financial Services
                        </option>
                        <option value="Insurance">Insurance</option>
                        <option value="Distribution, Import/Export">
                          Distribution, Import/Export
                        </option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Wholesale">Wholesale</option>
                        <option value="Cards & Gifts">Cards & Gifts</option>
                        <option value="Clothing & Accessories">
                          Clothing & Accessories
                        </option>
                        <option value="Department Stores, Sporting Goods">
                          Department Stores, Sporting Goods
                        </option>
                        <option value="General">General</option>
                        <option value="Jewelry">Jewelry</option>
                        <option value="Shoes">Shoes</option>
                        <option value="Animal Care & Supplies">
                          Animal Care & Supplies
                        </option>
                        <option value="Barber & Beauty Salons">
                          Barber & Beauty Salons
                        </option>
                        <option value="Beauty Supplies">Beauty Supplies</option>
                        <option value="Dry Cleaners & Laundromats">
                          Dry Cleaners & Laundromats
                        </option>
                        <option value="Exercise & Fitness">
                          Exercise & Fitness
                        </option>
                        <option value="Massage & Body Works">
                          Massage & Body Works
                        </option>
                        <option value="Nail Salons">Nail Salons</option>
                        <option value="Shoe Repairs">Shoe Repairs</option>
                        <option value="Tailors">Tailors</option>
                        <option value="Hotel, Motel & Extended Stay">
                          Hotel, Motel & Extended Stay
                        </option>
                        <option value="Moving & Storage">
                          Moving & Storage
                        </option>
                        <option value="Packaging & Shipping">
                          Packaging & Shipping
                        </option>
                        <option value="Transportation">Transportation</option>
                        <option value="Travel & Tourism">
                          Travel & Tourism
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="goodSource" class="form-label">
                        Good Source*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="goodSource"
                      >
                        <option selected={detailSupplier.goodssource}>
                          {detailSupplier.goodssource}
                        </option>
                        <option value="Distributor">Distributor</option>
                        <option value="Authorized Dealer">
                          Authorized Dealer{" "}
                        </option>
                        <option value="Official Company / Original">
                          Official Company / Original{" "}
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyRep" class="form-label">
                        Company Rep*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="companyRep"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["companyrep"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.companyrep}>
                          {detailSupplier.companyrep}
                        </option>
                        <option value="Sales Representative">
                          Sales Representative
                        </option>
                        <option value="Customer Service Representative">
                          Customer Service Representative
                        </option>
                        <option value="Marketing Specialist">
                          Marketing Specialist
                        </option>
                        <option value="Administrative Assistant">
                          Administrative Assistant
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyRepName" class="form-label">
                        Staff Rep*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyRepName"
                        defaultValue={detailSupplier.salesman}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["salesman"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="telpRep" class="form-label">
                        Telp Rep*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="telpRep"
                        defaultValue={detailSupplier.telephonesales}
                        maxLength={13}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["telephonesales"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="companyNPWP" class="form-label">
                        NPWP*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyNPWP"
                        defaultValue={detailSupplier.npwpno}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["npwpno"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="companyStatus" class="form-label">
                        Company Status*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="companyStatus"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["companystatus"]: e.target.value,
                          })
                        }
                      >
                        <option value={detailSupplier.companystatus}>
                          {detailSupplier.companystatus}
                        </option>
                        <option value="PKP">PKP</option>
                        <option value="NON-PKP">NON-PKP</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="paymentMethod" class="form-label">
                        Payment Method*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="paymentMethod"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["paymentmethod"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.paymentmethod}>
                          {detailSupplier.paymentmethod}
                        </option>
                        <option value="Cash">Cash</option>
                        <option value="Debit cards">Debit cards</option>
                        <option value="Credit Cards">Credit Cards</option>
                        <option value="Bank transfers">Bank transfers</option>
                        <option value="Direct debit">Direct debit</option>
                        <option value="Mobile payments">Mobile payments</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="paymentTerm" class="form-label">
                        Payment Term* (in a week)
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="paymentTerm"
                        defaultValue={detailSupplier.paymentterm}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["paymentterm"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankName" class="form-label">
                        Bank Name*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="bankName"
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["bankname"]: e.target.value,
                          })
                        }
                      >
                        <option selected={detailSupplier.bankname}>
                          {detailSupplier.bankname}
                        </option>
                        <option value="Bank Mandiri">Bank Mandiri</option>
                        <option value="Bank Rakyat Indonesia (BRI)">
                          Bank Rakyat Indonesia (BRI)
                        </option>
                        <option value="Bank Central Asia (BCA)">
                          Bank Central Asia (BCA)
                        </option>
                        <option value="Bank Negara Indonesia (BNI)">
                          Bank Negara Indonesia (BNI)
                        </option>
                        <option value="Bank Tabungan Negara (BTN)">
                          Bank Tabungan Negara (BTN)
                        </option>
                        <option value="CIMB Niaga">CIMB Niaga</option>
                        <option value="Bank Syariah Indonesia (BSI)">
                          Bank Syariah Indonesia (BSI)
                        </option>
                        <option value="Permata Bank">Permata Bank</option>
                        <option value="OCBC NISP">OCBC NISP</option>
                        <option value="Panin Bank">Panin Bank</option>
                        <option value="Bank KB Bukopin">Bank KB Bukopin</option>
                        <option value="Citibank Indonesia">
                          Citibank Indonesia
                        </option>
                        <option value="Bank CIMB Niaga">Bank CIMB Niaga</option>
                        <option value="Bank Danamon">Bank Danamon</option>
                        <option value="Bank DBS Indonesia">
                          Bank DBS Indonesia
                        </option>
                        <option value="Bank HSBC Indonesia">
                          Bank HSBC Indonesia
                        </option>
                        <option value="Bank KEB Hana Indonesia">
                          Bank KEB Hana Indonesia
                        </option>
                        <option value="Bank Mayapada">Bank Mayapada</option>
                        <option value="Bank Mega">Bank Mega</option>
                        <option value="Bank MNC Internasional">
                          Bank MNC Internasional
                        </option>
                        <option value="Bank OCBC NISP">Bank OCBC NISP</option>
                        <option value="Panin Bank">Panin Bank</option>
                        <option value="Permata Bank">Permata Bank</option>
                        <option value="Bank Sinarmas">Bank Sinarmas</option>
                        <option value="Bank UOB Indonesia">
                          Bank UOB Indonesia
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankAccountNo" class="form-label">
                        Bank Account No*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAccountNo"
                        defaultValue={detailSupplier.bankaccountno}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["bankaccountno"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankAccountName" class="form-label">
                        Bank Account Name
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAccountName"
                        defaultValue={detailSupplier.accountname}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["accountname"]: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div class="col-8 mb-2">
                      <label htmlFor="bankAddress" class="form-label">
                        Bank Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAddress"
                        defaultValue={detailSupplier.bankaddress}
                        onChange={(e) =>
                          setDetailSupplier({
                            ...detailSupplier,
                            ["bankaddress"]: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex flex-row-reverse">
                  <button
                    type="submit"
                    class="btn btn-warning flex-end"
                    onClick={() => UpdateSupplier()}
                    style={{ fontSize: "12px" }}
                  >
                    {updating ? "Updating.." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Edit;
