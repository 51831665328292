import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { removeUserSession } from "../../utils/Common";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import LoadingOverlay from "react-loading-overlay-ts";
import banner1 from "../assets/prc.png";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import "./Dashboard.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  // Var
  const [detailUser, setDetailUser] = useState([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  // Session
  const session = localStorage.getItem("user");
  const uID = JSON.parse(session).id;
  const hcode = JSON.parse(session).hotel_id;
  const role = JSON.parse(session).role_name;
  const udeptID = JSON.parse(session).dept_id;

  const [countPP, setCountPP] = useState(null);
  const [countPR, setCountPR] = useState(null);
  const [countItem, setCountItem] = useState(null);
  const [countPOGenerate, setCountPOGenerate] = useState(null);
  const [countPOSent, setCountPOSent] = useState(null);
  const [countPORecord, setCountPORecord] = useState(null);

  const [countItemReceive, setCountItemReceive] = useState(null);

  // CHART
  const [dataLabelHotel, setDataLabelHotel] = useState();
  const [dataOntime, setDataOntime] = useState();
  const [dataDelay, setDataDelay] = useState();
  const [dataOntimeHotel, setDataOntimeHotel] = useState();
  const [dataDelayHotel, setDataDelayHotel] = useState();

  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "ONTIME VS LATE DELIVERY GROUP BY MONTH ",
      },
    },
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "ONTIME VS LATE DELIVERY ",
      },
    },
  };

  const data1 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Ontime",
        data: dataOntime,
        backgroundColor: "rgb(112, 112, 244)",
      },
      {
        label: "Late",
        data: dataDelay,
        backgroundColor: "rgb(232, 81, 81)",
      },
    ],
  };

  const data2 = {
    labels: dataLabelHotel,
    datasets: [
      {
        label: "Ontime",
        data: dataOntimeHotel,
        backgroundColor: "rgb(112, 112, 244)",
      },
      {
        label: "Late",
        data: dataDelayHotel,
        backgroundColor: "rgb(232, 81, 81)",
      },
    ],
  };

  const getDataChart = async () => {
    try {
      const response = await axios.get(`/api/chart/receive/items`, {
        headers: headers,
      });
      setDataLabelHotel(response.data[0]);
      setDataOntime(response.data[1]);
      setDataDelay(response.data[2]);
      setDataOntimeHotel(response.data[3]);
      setDataDelayHotel(response.data[4]);
    } catch (error) {
      console.log("Error getAPIListPPW: ", error);
    }
  };

  const getUser = async () => {
    try {
      const response = await axios.post(
        "/api/detail/user",
        { uID, hcode },
        { headers }
      );
      setDetailUser(response.data);
    } catch (error) {
      console.log("Error getAPIUserDetail: ", error);
    }
  };

  const getCountPP = async () => {
    try {
      const response = await axios.post(
        `/api/count/proposal`,
        { hcode, udeptID },
        { headers: headers }
      );
      setCountPP(response.data);
    } catch (error) {
      console.log("Error getAPICountPP: ", error);
    }
  };

  const getCountPR = async () => {
    try {
      const response = await axios.post(
        `/api/count/request`,
        { hcode, udeptID },
        { headers: headers }
      );
      setCountPR(response.data);
    } catch (error) {
      console.log("Error getAPICountRequest: ", error);
    }
  };

  const getCountItemRegister = async () => {
    try {
      const response = await axios.get(`/api/count/items`, {
        params: { hcode, udeptID },
        headers: headers,
      });
      setCountItem(response.data);
    } catch (error) {
      console.log("Error getAPICountRequest: ", error);
    }
  };

  const getCountPOGenerate = async () => {
    try {
      const response = await axios.get(`/api/count/po/generate`, {
        params: { hcode, udeptID },
        headers: headers,
      });
      // console.log(response.data)
      setCountPOGenerate(response.data[0]);
      setCountPORecord(response.data[1]);
    } catch (error) {
      console.log("Error getAPICountRequest: ", error);
    }
  };

  const getCountPOSent = async () => {
    try {
      const response = await axios.get(`/api/count/po/sent`, {
        params: { hcode, udeptID },
        headers: headers,
      });
      // console.log(response.data)
      setCountPOSent(response.data);
    } catch (error) {
      console.log("Error getAPICountRequest: ", error);
    }
  };

  const getCountItemReceive = async () => {
    try {
      const response = await axios.get(`/api/count/item/receive`, {
        params: { hcode, udeptID },
        headers: headers,
      });
      // console.log(response.data)
      setCountItemReceive(response.data);
    } catch (error) {
      console.log("Error getAPICountRequest: ", error);
    }
  };

  const [currPass, setCurrPass] = useState(null);
  const [newPass, setNewPass] = useState(null);
  const [confNewPass, setConfNewPass] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [IMGUpload, setIMGUpload] = useState(null);

  const [dataReportGroup1, setDataReportGroup1] = useState([]);
  const [dataReportGroup2, setDataReportGroup2] = useState([]);
  const [dataReportGroup3, setDataReportGroup3] = useState([]);
  const [dataReportGroup4, setDataReportGroup4] = useState([]);

  const uploadImage = (e) => {
    // console.log(e)
    setActiveLoading(true);
    let file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "otmcdtyq");
    formData.append("cloud_name", "cloud-prima-hotel-indonesia");
    fetch(
      "https://api.cloudinary.com/v1_1/cloud-prima-hotel-indonesia/image/upload",
      {
        method: "post",
        body: formData,
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        // console.log(data.url)
        setIMGUpload(data.url);
        setDetailUser({ ...detailUser, ["img_user"]: data.url });
        setActiveLoading(false);
      });
  };

  const updateProfile = () => {
    if (
      !detailUser.name ||
      !detailUser.addr_user ||
      !detailUser.email ||
      !detailUser.phone
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      Swal.fire({
        title: "Do you want to update your profile?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Process Here
          axios
            .post(`/api/update/user`, { detailUser }, { headers: headers })
            .then(function (response) {
              // history.push('/list/item');
              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const updatePassword = () => {
    if (!currPass || !newPass || !confNewPass) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      Swal.fire({
        title:
          "Do you want to update your password, it will be logout your current account?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Process Here
          axios
            .post(
              `/api/update/password`,
              { uID, currPass, newPass, confNewPass },
              { headers: headers }
            )
            .then(function (response) {
              console.log(response.data);
              if (response.data == 1) {
                Swal.fire({
                  icon: "success",
                  text: "Your account will be logout for a moment",
                });

                removeUserSession();
                window.location.reload();
              } else {
                Swal.fire({
                  icon: "error",
                  text: response.data,
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  // COUNT PR BY USER HOTEL
  const getDataPRGroup1 = () => {
    axios
      .get(`/api/count/pr/group1`, { headers: headers })
      .then(function (response) {
        // console.log(response.data)
        setDataReportGroup1(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPICountPP: ", error);
      });
  };

  const getDataPRGroup2 = () => {
    axios
      .get(`/api/count/pr/group2`, { headers: headers })
      .then(function (response) {
        // console.log(response.data);
        setDataReportGroup2(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPICountPP: ", error);
      });
  };

  const getDataPRGroup3 = () => {
    axios
      .get(`/api/count/pr/group3`, { headers: headers })
      .then(function (response) {
        // console.log(response.data);
        setDataReportGroup3(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPICountPP: ", error);
      });
  };

  const getDataPRGroup4 = () => {
    axios
      .get(`/api/count/pr/group4`, { headers: headers })
      .then(function (response) {
        // console.log(response.data);
        setDataReportGroup4(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPICountPP: ", error);
      });
  };

  const debouncedGetDataChart = _.debounce(getDataChart, 500);
  const debouncedGetUser = _.debounce(getUser, 500);
  const debouncedGetCountPP = _.debounce(getCountPP, 500);
  const debouncedGetCountPR = _.debounce(getCountPR, 500);
  const debouncedGetCountItemRegister = _.debounce(getCountItemRegister, 500);
  const debouncedGetCountPOGenerate = _.debounce(getCountPOGenerate, 500);
  const debouncedGetCountPOSent = _.debounce(getCountPOSent, 500);
  const debouncedGetCountItemReceive = _.debounce(getCountItemReceive, 500);
  const debouncedGetDataPRGroup1 = _.debounce(getDataPRGroup1, 500);
  const debouncedGetDataPRGroup2 = _.debounce(getDataPRGroup2, 500);
  const debouncedGetDataPRGroup3 = _.debounce(getDataPRGroup3, 500);
  const debouncedGetDataPRGroup4 = _.debounce(getDataPRGroup4, 500);

  useEffect(() => {
    debouncedGetDataChart();
    debouncedGetUser();
    debouncedGetCountPP();
    debouncedGetCountPR();
    debouncedGetCountItemRegister();
    debouncedGetCountPOGenerate();
    debouncedGetCountPOSent();
    debouncedGetCountItemReceive();
    debouncedGetDataPRGroup1();
    debouncedGetDataPRGroup2();
    debouncedGetDataPRGroup3();
    debouncedGetDataPRGroup4();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={loadingActive}
        spinner
        text="Uploading your content..."
      >
        <HEAD />
        <SIDE />

        <main id="main" className="main">
          <div className="pagetitle">
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", marginLeft: "7px" }}
            >
              Dashboard
            </h6>
            <nav>
              <ol className="breadcrumb mt-1 mb-2 ms-2">
                <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                  Home
                </li>
                <li
                  style={{ fontSize: "13px" }}
                  className="breadcrumb-item active"
                >
                  Dashboard
                </li>
              </ol>
            </nav>
          </div>

          <section>
            <div className="row" style={{ fontSize: "14px" }}>
              <div className="col-12">
                <div className="card">
                  <div className="card-body pt-2">
                    <ul
                      className="nav nav-tabs nav-tabs-bordered"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          data-bs-toggle="tab"
                          data-bs-target="#feed-overview"
                          aria-selected="true"
                          role="tab"
                        >
                          Feed
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-overview"
                          aria-selected="true"
                          role="tab"
                        >
                          Overview
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-edit"
                          aria-selected="false"
                          role="tab"
                          tabindex="-1"
                        >
                          Edit Profile
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          data-bs-toggle="tab"
                          data-bs-target="#profile-change-password"
                          aria-selected="false"
                          role="tab"
                          tabindex="-1"
                        >
                          Change Password
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content pt-2">
                      <div
                        className="tab-pane fade feed-overview active show"
                        id="feed-overview"
                        role="tabpanel"
                      >
                        <div className="row mt-4 mb-3 p-2 custom-feed">
                          <div className="col-md-12 col-sm-12">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="card-title no-wrap me-5">
                                Feed <span>| Today</span>
                              </h5>
                              <marquee className="custom-marquee ms-5">
                                <div
                                  className="alert custom-alert alert-dismissible fade show m-0 p-2"
                                  role="alert"
                                >
                                  Welcome to Eapproval V1.2 | *We still search
                                  bug and fix the bug , be patient and relax
                                </div>
                              </marquee>
                            </div>
                          </div>
                          {/* <div className='col-md-2 col-sm-12 p-2 text-center'>
                            <img className='img-fluid rounded ' src={banner1} alt="Banner" />
                          </div> */}
                        </div>

                        <div className="row mt-1">
                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="card info-card sales-card custom-card"
                              onClick={() => history.push("/waiting/proposal")}
                            >
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  {" "}
                                  Proposal<span></span>
                                </h5>

                                <div className="d-flex align-items-center">
                                  <div className="card-icon d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-folder2-open"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h6>{countPP}</h6>
                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="card info-card sales-card custom-card"
                              onClick={() => history.push("/waiting/request")}
                            >
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  Request <span></span>
                                </h5>

                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-folder-symlink"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h6>{countPR}</h6>
                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="card info-card sales-card custom-card">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  PO's <span>| Print</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-archive"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h6>
                                      {countPOGenerate}/{countPORecord}
                                    </h6>

                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="card info-card sales-card custom-card">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  PO <span>| Sent</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-archive"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h6>
                                      {countPOSent}/{countPOGenerate}
                                    </h6>
                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="card info-card sales-card custom-card"
                              onClick={() => history.push("/receive/item")}
                            >
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  Item <span>| Receive</span>
                                </h5>

                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-folder-plus"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h6>
                                      {countItemReceive}/{countPOSent}
                                    </h6>
                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-xxl-2 col-md-2"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="card info-card sales-card custom-card">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "16px" }}
                                >
                                  Item <span>| Request</span>
                                </h5>
                                <div className="d-flex align-items-center">
                                  <div className="card-icon rounded-circle d-flex align-items-center justify-content-center custom-icon">
                                    <i
                                      className="bi bi-archive"
                                      style={{
                                        fontSize: "22px",
                                        color: "#fff",
                                      }}
                                    ></i>
                                  </div>
                                  <div className="ps-4">
                                    <h4>{countItem}</h4>
                                    {/* <span className="text-success small pt-1 fw-bold">12%</span> <span className="text-muted small pt-2 ps-1">increase</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-1">
                          <div className="col-xxl-3 col-md-3">
                            <div className="card info-card sales-card">
                              <div className="card-body">
                                <h6 className="card-title">
                                  AS3,JU1 | <span>{moment().format("ll")}</span>
                                </h6>

                                <div className="d-flex align-items-center">
                                  <div className="ps-1">
                                    <div>
                                      <span>Purchase Request</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/waiting/request/daily/1")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.prwaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.prprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <span className="text-success small pt-1 fw-bold">
                                      {dataReportGroup1.prdone}
                                    </span>{" "}
                                    <span className="text-muted small pt-2 ps-1">
                                      Done
                                    </span>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "waiting/request/outstanding/1"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.proutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>

                                  <div className="ps-4">
                                    <div>
                                      <span>Purchase Order</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/purchase/order/daily/1")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.powaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.poprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.podone}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Done
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "/purchase/order/outstanding/1"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup1.pooutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-md-3">
                            <div className="card info-card sales-card">
                              <div className="card-body">
                                <h6 className="card-title">
                                  AS2,JU2,YB1 |{" "}
                                  <span>{moment().format("ll")}</span>
                                </h6>

                                <div className="d-flex align-items-center">
                                  <div className="ps-1">
                                    <div>
                                      <span>Purchase Request</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/waiting/request/daily/2")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.prwaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.prprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <span className="text-success small pt-1 fw-bold">
                                      {dataReportGroup2.prdone}
                                    </span>{" "}
                                    <span className="text-muted small pt-2 ps-1">
                                      Done
                                    </span>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "waiting/request/outstanding/2"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.proutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>

                                  <div className="ps-4">
                                    <div>
                                      <span>Purchase Order</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/purchase/order/daily/2")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.powaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.poprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.podone}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Done
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "/purchase/order/outstanding/2"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup2.pooutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-md-3">
                            <div className="card info-card sales-card">
                              <div className="card-body">
                                <h5
                                  className="card-title"
                                  style={{ fontSize: "12px" }}
                                >
                                  AS1,AS4,AS5,ST1,YB2 |{" "}
                                  <span>{moment().format("ll")}</span>
                                </h5>

                                <div className="d-flex align-items-center">
                                  <div className="ps-1">
                                    <div>
                                      <span>Purchase Request</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/waiting/request/daily/3")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.prwaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.prprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <span className="text-success small pt-1 fw-bold">
                                      {dataReportGroup3.prdone}
                                    </span>{" "}
                                    <span className="text-muted small pt-2 ps-1">
                                      Done
                                    </span>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "waiting/request/outstanding/3"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.proutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>

                                  <div className="ps-4">
                                    <div>
                                      <span>Purchase Order</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/purchase/order/daily/3")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.powaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.poprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.podone}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Done
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "/purchase/order/outstanding/3"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup3.pooutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-3 col-md-3">
                            <div className="card info-card sales-card">
                              <div className="card-body">
                                <h6 className="card-title">
                                  PHI | <span>{moment().format("ll")}</span>
                                </h6>

                                <div className="d-flex align-items-center">
                                  <div className="ps-1">
                                    <div>
                                      <span>Purchase Request</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/waiting/request/daily/4")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.prwaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.prprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <span className="text-success small pt-1 fw-bold">
                                      {dataReportGroup4.prdone}
                                    </span>{" "}
                                    <span className="text-muted small pt-2 ps-1">
                                      Done
                                    </span>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "waiting/request/outstanding/4"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.proutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>

                                  <div className="ps-4">
                                    <div>
                                      <span>Purchase Order</span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push("/purchase/order/daily/4")
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.powaiting}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        New
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.poprocess}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Process
                                      </span>
                                    </div>
                                    <div>
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.podone}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        Done
                                      </span>
                                    </div>
                                    <div
                                      onClick={() =>
                                        history.push(
                                          "/purchase/order/outstanding/4"
                                        )
                                      }
                                    >
                                      <span className="text-success small pt-1 fw-bold">
                                        {dataReportGroup4.pooutstanding}
                                      </span>{" "}
                                      <span className="text-muted small pt-2 ps-1">
                                        OutStanding
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* for chart */}
                        <div className="row" style={{ fontSize: "14px" }}>
                          <div className="col-6">
                            <div className="card">
                              <div className="card-body pt-3">
                                {/* Chart PP 1 Year */}
                                <Bar
                                  options={options1}
                                  data={data1}
                                  height={"120px"}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="card">
                              <div className="card-body pt-3">
                                {/* Chart PP 1 Year */}
                                <Bar
                                  options={options2}
                                  data={data2}
                                  height={"120px"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade p-2 profile-overview justify-center"
                        id="profile-overview"
                        role="tabpanel"
                      >
                        <div className="profile-overview-container">
                          <div className="profile-overview">
                            <h5 className="card-title p-0">Welcome</h5>
                            <img
                              src={detailUser.img_user}
                              alt="Profile"
                              style={{ width: "80px", height: "80px" }}
                            />
                            <h5 className="card-title">Profile Details</h5>

                            <div className="row">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Full Name
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  {detailUser.name}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Role
                                </div>
                                <div className="col-lg-9 col-md-8">{role}</div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Department
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  {detailUser.nm_department}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Address
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  {detailUser.addr_user}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Phone
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  +{detailUser.phone}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-3 col-md-4 label">
                                  Email
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  {detailUser.email}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Edit Profile */}
                      <div
                        className="tab-pane fade profile-edit pt-3"
                        id="profile-edit"
                        role="tabpanel"
                      >
                        <div className="row mb-3">
                          <label
                            htmlFor="profileImage"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Profile Image
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <img
                              src={selectedImage || detailUser.img_user}
                              alt="Profile"
                              htmlFor="itemImage"
                              style={{ width: "200px", height: "190px" }}
                            />
                            <div className="pt-2">
                              <input
                                type="file"
                                className="form-control form-control-sm"
                                onChange={(e) => uploadImage(e)}
                                id="itemImage"
                              ></input>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="fullName"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Full Name
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="fullName"
                              type="text"
                              className="form-control form-control-sm"
                              id="fullName"
                              defaultValue={detailUser.name}
                              onChange={(e) =>
                                setDetailUser({
                                  ...detailUser,
                                  name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="Address"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Address
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="address"
                              type="text"
                              className="form-control form-control-sm"
                              id="Address"
                              defaultValue={detailUser.addr_user}
                              onChange={(e) =>
                                setDetailUser({
                                  ...detailUser,
                                  addr_user: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="Phone"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Phone
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="phone"
                              type="text"
                              className="form-control form-control-sm"
                              id="Phone"
                              defaultValue={detailUser.phone}
                              onChange={(e) =>
                                setDetailUser({
                                  ...detailUser,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="Email"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Email
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="email"
                              type="email"
                              className="form-control form-control-sm"
                              id="Email"
                              defaultValue={detailUser.email}
                              onChange={(e) =>
                                setDetailUser({
                                  ...detailUser,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            type="button"
                            style={{
                              fontSize: "12px",
                              backgroundColor: "#ffce54",
                            }}
                            className="btn"
                            onClick={updateProfile}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>

                      {/* Edit Pass */}
                      <div
                        className="tab-pane fade pt-3"
                        id="profile-change-password"
                        role="tabpanel"
                      >
                        <div className="row mb-3">
                          <label
                            htmlFor="currentPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Current Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="password"
                              type="password"
                              className="form-control form-control-sm"
                              id="currentPassword"
                              onChange={(e) => setCurrPass(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="newPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            New Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="newpassword"
                              type="password"
                              className="form-control form-control-sm"
                              id="newPassword"
                              onChange={(e) => setNewPass(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            htmlFor="renewPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            Re-enter New Password
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="renewpassword"
                              type="password"
                              className="form-control form-control-sm"
                              id="renewPassword"
                              onChange={(e) => setConfNewPass(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            type="button"
                            style={{
                              fontSize: "12px",
                              backgroundColor: "#ffce54",
                            }}
                            className="btn"
                            onClick={updatePassword}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </LoadingOverlay>
    </>
  );
}

export default Dashboard;
