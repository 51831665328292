import React, { useEffect, useState } from "react";
import { getToken, removeUserSession } from "../utils/Common";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
function Header() {
  const history = useHistory();
  const [side, setSide] = useState(false);

  // Session
  const session = localStorage.getItem("user");
  const uName = JSON.parse(session).name;
  const uDept = JSON.parse(session).nm_department;
  const uRole = JSON.parse(session).role_name;
  const uAva = JSON.parse(session).img_user;

  const signOut = () => {
    Swal.fire({
      title: "Do you want to logout  ?",
      text: "This process will direct to login page",
      showDenyButton: true,
      confirmButtonText: "Sure",
      denyButtonText: `Cancel`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        removeUserSession();
        window.location.reload();
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  useEffect(() => {
    if (side) {
      document.body.classList.add("toggle-sidebar");
    } else {
      document.body.classList.remove("toggle-sidebar");
    }
  }, [side]);

  const handleMediaQueryChange = (event) => {
    console.log("Media query matched:", event.matches);
    if (event.matches) {
      setSide(true); // Close the sidebar when the screen is smaller than 768px
    } else {
      setSide(false); // Open the sidebar when the screen is larger than 768px
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1299px)");
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Initial check
    if (mediaQuery.matches) {
      setSide(false);
    }

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <>
      <header
        id="header"
        style={{ backgroundColor: "#292927" }}
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <i
            className="bi bi-list toggle-sidebar-btn"
            style={{ marginRight: "3%", color: "white" }}
            onClick={() => setSide(!side)}
          ></i>
          <h5 style={{ marginTop: "6px", fontWeight: "bold", color: "#fff" }}>
            Purchasing
          </h5>
          <a className="logo d-flex align-items-center">
            {/* <img src="assets/img/logo.png" alt="" /> */}
            <span className="d-none d-lg-block"></span>
          </a>
        </div>

        {/* <div className="search-bar">
        <form className="search-form d-flex align-items-center" >
            <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
            <button type="submit" title="Search"><i className="bi bi-search"></i></button>
        </form>
    </div> */}

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {/* <li className="nav-item dropdown">

            <a className="nav-link nav-icon" data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                You have 4 new notifications
                <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                </li>
                <li>
                <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                <i className="bi bi-exclamation-circle text-warning"></i>
                <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                </div>
                </li>

                <li>
                <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                <i className="bi bi-x-circle text-danger"></i>
                <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                </div>
                </li>

                <li>
                <hr className="dropdown-divider" />
                </li>

                <li className="notification-item">
                <i className="bi bi-check-circle text-success"></i>
                <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                </div>
                </li>

                <li>
                <hr className="dropdown-divider"/>
                </li>

                <li className="notification-item">
                <i className="bi bi-info-circle text-primary"></i>
                <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                </div>
                </li>

                <li>
                <hr className="dropdown-divider" />
                </li>
                <li className="dropdown-footer">
                <a href="#">Show all notifications</a>
                </li>

            </ul>

        </li> */}

            {/* <li className="nav-item dropdown">

        <a className="nav-link nav-icon" data-bs-toggle="dropdown">
            <i className="bi bi-chat-left-text"></i>
            <span className="badge bg-success badge-number">3</span>
        </a>

        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
            <li className="dropdown-header">
            You have 3 new messages
            <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
            </li>
            <li>
            <hr className="dropdown-divider" />
            </li>

            <li className="message-item">
            <a href="#">
                <img src="assets/img/messages-1.jpg" alt="" className="rounded-circle" />
                <div>
                <h4>Maria Hudson</h4>
                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                <p>4 hrs. ago</p>
                </div>
            </a>
            </li>
            <li>
            <hr className="dropdown-divider" />
            </li>

            <li className="message-item">
            <a href="#">
                <img src="assets/img/messages-2.jpg" alt="" className="rounded-circle" />
                <div>
                <h4>Anna Nelson</h4>
                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                <p>6 hrs. ago</p>
                </div>
            </a>
            </li>
            <li>
            <hr className="dropdown-divider" />
            </li>

            <li className="message-item">
            <a href="#">
                <img src="assets/img/messages-3.jpg" alt="" className="rounded-circle" />
                <div>
                <h4>David Muldon</h4>
                <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                <p>8 hrs. ago</p>
                </div>
            </a>
            </li>
            <li>
            <hr className="dropdown-divider" />
            </li>

            <li className="dropdown-footer">
            <a href="#">Show all messages</a>
            </li>

        </ul>

        </li> */}

            <li
              className="nav-item dropdown pe-3"
              style={{ cursor: "pointer" }}
            >
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0"
                data-bs-toggle="dropdown"
              >
                <img
                  src={uAva}
                  alt="Profile"
                  className="rounded-circle"
                  style={{ width: 30, height: 30 }}
                />
                <span
                  className="d-none d-md-block dropdown-toggle ps-2"
                  style={{ color: "white" }}
                >
                  {uName}
                </span>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{uName}</h6>
                  <span>{uRole}</span>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <a
                    className="dropdown-item d-flex align-items-center"
                    onClick={() => signOut()}
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
