import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { removeUserSession } from "../../utils/Common";
import moment from "moment";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import LoadingOverlay from "react-loading-overlay-ts";
import banner1 from "../assets/online.gif";
import { CSVLink } from "react-csv";
import _ from "lodash";

function POReport() {
  // Var
  const [detailUser, setDetailUser] = useState([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  // Session
  const session = localStorage.getItem("user");
  const uID = JSON.parse(session).id;
  const hcode = JSON.parse(session).hotel_id;
  const role = JSON.parse(session).role_name;
  const udeptID = JSON.parse(session).dept_id;

  const [btnPrint, setBtnPrint] = useState(false);
  const [startDate, setStartDate] = useState();
  const [untilDate, setUntilDate] = useState();
  const [reportKind, setReportKind] = useState(999);
  const [hotelReport, setHotelReport] = useState(999);
  const [departmentReport, setDepartmentReport] = useState(999);
  const [reportPO, setReportPO] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [hcode, headers]);
  const [departmentList, setDepartmentList] = useState([]);
  const getLisDepartment = () => {
    axios
      .get(`/api/list/departments`, { headers: headers })
      .then(function (response) {
        setDepartmentList(response.data);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const generateReport = () => {
    setActiveLoading(true);

    if (
      !startDate ||
      !untilDate ||
      !hotelReport ||
      !reportKind ||
      !departmentReport
    ) {
      Swal.fire({
        position: "bottom-start",
        icon: "info",
        title: "Please complete the search form",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      //Process Search
      axios
        .post(
          `/api/generate/report/po`,
          {
            startDate,
            untilDate,
            reportKind,
            hotelReport,
            departmentReport,
          },
          { headers: headers }
        )
        .then(function (response) {
          console.log(response.data);
          setReportPO(response.data);
          setActiveLoading(false);
          if (response.data.length > 0) {
            setBtnPrint(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const heading = [
    { label: "PR No", key: "nomer" },
    { label: "Need", key: "date_need" },
    { label: "Hotel", key: "nm_hotel" },
    { label: "Department", key: "nm_department" },
    { label: "Header/Remark.", key: "remark" },
    { label: "Created PO", key: "createdPO" },
    { label: "Sent PO", key: "date_po_send" },
    { label: "File", key: "file_po" },
    { label: "Supplier", key: "companyname" },
    { label: "Total Price", key: "receive_item_date" },
    { label: "Receive Quantity", key: "qty_receive" },
  ];

  const csvExport = {
    data: reportPO,
    headers: heading,
    filename: "po_report.csv",
  };

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);
  const debouncedGetLisDepartment = _.debounce(getLisDepartment, 100);

  // UseEffect with debounced functions
  useEffect(() => {
    debouncedGetListHotelbyUser();
    debouncedGetLisDepartment();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={loadingActive}
        spinner
        text="Loading your content..."
      >
        <HEAD />
        <SIDE />

        <main id="main" class="main">
          <div className="pagetitle">
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", marginLeft: "7px" }}
            >
              Report
            </h6>
            <nav>
              <ol className="breadcrumb mt-1 mb-2 ms-2">
                <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                  Purchase Order
                </li>
                <li
                  style={{ fontSize: "13px" }}
                  className="breadcrumb-item active"
                >
                  Report
                </li>
              </ol>
            </nav>
          </div>

          <section>
            <div className="row" style={{ fontSize: "14px" }}>
              <div className="col-12">
                <div class="card">
                  <div class="card-body ps-4 pt-3">
                    <h5 class="card-title">Generate Report</h5>

                    <div className="row">
                      <div className="col-3">
                        <label>Start</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="startDate"
                          placeholder="Choose Date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>

                      <div className="col-3">
                        <label>End</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="untilDate"
                          onChange={(e) => setUntilDate(e.target.value)}
                        />
                      </div>

                      <div className="col-2">
                        <label>Kind</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setReportKind(e.target.value)}
                        >
                          <option value="999">All</option>
                          <option value="1">Printed</option>
                          <option value="2">Sent</option>
                          <option value="3">Receive</option>
                        </select>
                      </div>

                      <div className="col-2">
                        <label>Department</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setDepartmentReport(e.target.value)}
                        >
                          <option value="999">All Department</option>
                          {departmentList.map((x, t) => (
                            <option key={t} value={x.id_department}>
                              {x.nm_department}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-2">
                        <label>For Hotel</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setHotelReport(e.target.value)}
                        >
                          <option value="999">All Hotel</option>
                          {hotelList.map((x, t) =>
                            x.map((v, k) => (
                              <option key={k} value={v.id_hotel}>
                                {v.code_hotel} - {v.nm_hotel}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="row mt-3 mb-3">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginRight: "10px", fontSize: "12px" }}
                            onClick={() => generateReport()}
                          >
                            Search
                          </button>
                          {btnPrint && (
                            <CSVLink
                              {...csvExport}
                              separator={";"}
                              style={{
                                backgroundColor: "#fcc404",
                                padding: "8px",
                                paddingRight: "12px",
                                paddingLeft: "12px",
                                borderRadius: 8,
                                fontSize: "12px",
                                color: "#000",
                              }}
                            >
                              Export to Excel
                            </CSVLink>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        {reportPO.length > 0 ? (
                          <table class="table table-bordered">
                            <thead>
                              <tr style={{ fontSize: "12px" }}>
                                <th scope="col">PR NO.</th>
                                <th scope="col">Date Need </th>
                                <th scope="col">Header/Remark</th>
                                {/* <th scope="col">PO No.</th> */}

                                <th scope="col" style={{ width: "120px" }}>
                                  Created PO
                                </th>
                                <th scope="col" style={{ width: "100px" }}>
                                  Sent PO
                                </th>
                                <th scope="col">File PO</th>
                                <th scope="col">Supplier</th>
                                <th scope="col" style={{ width: "120px" }}>
                                  Receive Item Date
                                </th>
                                <th scope="col">Receive Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportPO?.map((v, k) => (
                                <tr style={{ fontSize: "10px" }}>
                                  <td>{v.nomer}</td>
                                  <td>{moment(v.date_need).format("ll")}</td>
                                  <td>{v.remark}</td>
                                  {/* <td>{v.id_po}</td> */}

                                  <td>{moment(v.createdPO).format("ll")}</td>
                                  <td>{moment(v.date_po_send).format("ll")}</td>
                                  <td>{v.file_po}</td>
                                  <td>{v.companyname}</td>
                                  <td>
                                    {moment(v.receive_item_date).format("ll")}
                                  </td>
                                  <td>{v.qty_receive}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginBottom: "5%" }}
                          >
                            No Data
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </LoadingOverlay>
    </>
  );
}

export default POReport;
