import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { getToken } from "../../utils/Common";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import { Image, Transformation } from "cloudinary-react";
import { Modal, ModalFooter } from "react-bootstrap";
import _ from "lodash";

function WaitingItem() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;
  const [loading, setLoading] = useState(true);

  // Var
  const [listItem, setListItem] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const [openModalImg, setOpenModalImg] = useState(false);
  const [imgItem, setImgItem] = useState();

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log("Error API Hotel: ", error);
      });
  }, [hcode, headers]);

  //const ExpandedComponent = ({ data }) => <pre>{data.title}</pre>;

  const openImage = (url) => {
    setImgItem(url);
    setOpenModalImg(true);
  };

  const downloadItemList = () => {
    axios
      .get(
        `/api/generate/excel/items/${hcode}/${udeptID}`,
        { responseType: "blob" },
        { headers: headers }
      )
      .then(function (response) {
        console.log(response.data);
        FileDownload(response.data, "item_list.xlsx");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "ID",
      width: "100px",
      wrap: true,
      selector: (row) => row.id_item,
    },
    {
      name: "Thumbnail",
      grow: 0,
      cell: (row) => (
        <Image
          style={{ width: "80px" }}
          cloudName="cloud-prima-hotel-indonesia"
          publicId={row.img_item}
          onClick={() => openImage(row.img_item)}
        >
          <Transformation overlay="phi_png" />
          <Transformation width="500" crop="scale" />
          <Transformation opacity="20" />
          <Transformation flags="layer_apply" gravity="center" />
        </Image>
      ),
    },
    {
      name: "Item",
      width: "200px",
      wrap: true,
      selector: (row) => row.nama_item,
    },
    {
      name: "Group",
      wrap: true,
      cell: (row) => (
        <div>
          <b>{row.group_item} </b>
        </div>
      ),
    },
    {
      name: "Min Order",
      wrap: true,
      cell: (row) => (
        <div>
          <i>
            {row.min_order}/{row.unit}{" "}
          </i>
        </div>
      ),
    },
    {
      name: "Item Desc",
      wrap: true,
      cell: (row) => (
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "#580C30",
          }}
        >
          {row.desc_item}
        </div>
      ),
    },
    {
      name: "Dept",
      cell: (row) => row.code_department,
    },
    {
      name: "For",
      cell: (row) => row.code_hotel,
    },
    {
      name: "Status",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.stts_item === 0 ? "Active" : "Waiting"}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div>
          <i
            class="bi bi-search"
            onClick={() => history.push(`/detail/item/${row.id_item}`)}
          ></i>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "13px",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
      },
    },
  };

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = listItem.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const getListitemWaiting = async (hotel) => {
    setLoading(true);
    try {
      let response;
      if (hotel) {
        const hcode = hotel;
        response = await axios.get(
          `/api/list/item/waiting`,
          { hcode: hcode },
          { headers: headers }
        );
      } else {
        response = await axios.get(`/api/list/item/waiting`, {
          headers: headers,
        });
      }
      console.log(response.data);
      setListItem(Object.values(response.data).flatMap((arr) => arr));
    } catch (error) {
      console.log("Error getAPIListPPW: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetListitemWaiting = _.debounce(getListitemWaiting, 100);
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);

  // useEffect to trigger debounced functions on initial render
  useEffect(() => {
    debouncedGetListitemWaiting();
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Waiting Item
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Item Waiting
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                List
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-0 pb-2 mb-2 mt-3">
                    <div className="col-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "12px" }}
                        onClick={() => debouncedGetListitemWaiting()}
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "1%" }}
                        onClick={() => downloadItemList()}
                      >
                        <i className="bi bi-printer"></i>
                      </button>
                    </div>

                    <div className="col-7 ps-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>

                    <div className="col-4">
                      <select
                        id="hotelSelect"
                        className="form-select form-select-sm"
                        onChange={(e) =>
                          debouncedGetListitemWaiting(e.target.value)
                        }
                      >
                        <option>Group By Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div style={{ marginTop: "10px" }}>Loading...</div>
                    </div>
                  ) : (
                    <div className="row">
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="440px"
                        pagination
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Modal Show Image Item */}
      <Modal show={openModalImg} onHide={() => setOpenModalImg(false)}>
        <Modal.Body>
          <div class="row">
            <div class="col-lg-12 col-md-12 text-center">
              <Image
                style={{ width: "400px", height: "380px" }}
                cloudName="cloud-prima-hotel-indonesia"
                publicId={imgItem}
              >
                <Transformation overlay="phi_png" />
                <Transformation width="500" crop="scale" />
                <Transformation opacity="20" />
                <Transformation flags="layer_apply" gravity="center" />
              </Image>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WaitingItem;
