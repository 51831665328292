import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function ApproveProposal() {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  // Var
  const [purchaseProposal, setPurchaseProposal] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log("Error API Hotel: ", error);
      });
  }, [hcode, headers]);
  const ExpandedComponent = ({ data }) => <pre>{data.title}</pre>;

  const columns = [
    {
      name: "PP No.",
      width: "200px",
      wrap: true,
      selector: (row) => row.nomer,
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },
    {
      name: "Remark",
      wrap: true,
      cell: (row) => (
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "#580C30",
          }}
        >
          {row.remark}
        </div>
      ),
    },
    {
      name: "Created",
      cell: (row) => Moment(row.created_at).format("LL"),
    },
    {
      name: " Need For",
      cell: (row) => Moment(row.date_need).format("LL"),
    },
    {
      name: "Status",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.statusPP}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div>
          <i className="bi bi-search"></i>
        </div>
      ),
    },
  ];

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = purchaseProposal.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const getPurchaseProposalApprove = useCallback(
    (hotel) => {
      if (hotel) {
        const hcode = hotel;
        axios
          .post(
            `/api/approve/proposal`,
            { hcode, udeptID },
            { headers: headers }
          )
          .then(function (response) {
            console.log(response.data);
            setPurchaseProposal(
              Object.values(response.data).flatMap((arr) => arr)
            );
          })
          .catch(function (error) {
            console.log("Error getAPIListPPA: ", error);
          });
      } else {
        axios
          .post(
            `/api/approve/proposal`,
            { hcode, udeptID },
            { headers: headers }
          )
          .then(function (response) {
            console.log(response.data);
            setPurchaseProposal(
              Object.values(response.data).flatMap((arr) => arr)
            );
          })
          .catch(function (error) {
            console.log("Error getAPIListPPA: ", error);
          });
      }
    },
    [udeptID, hcode, headers]
  );

  const debouncedGetPurchaseProposalApprove = _.debounce(
    getPurchaseProposalApprove,
    200
  );
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);

  // Your useEffect hook
  useEffect(() => {
    // Call the debounced functions
    debouncedGetPurchaseProposalApprove();
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Purchase Proposal</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item ">Purchase Proposal</li>
              <li className="breadcrumb-item active">Process to PR</li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                    >
                      <i className="bi bi-plus-circle"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => debouncedGetPurchaseProposalApprove()}
                    >
                      <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                    >
                      <i className="bi bi-printer"></i>
                    </button>
                  </div>

                  <div className="row p-2 mb-2">
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>

                    <div className="col-4">
                      <select
                        id="hotelSelect"
                        className="form-select"
                        onChange={(e) =>
                          debouncedGetPurchaseProposalApprove(e.target.value)
                        }
                      >
                        <option>Group By Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      fixedHeader
                      fixedHeaderScrollHeight="440px"
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ApproveProposal;
