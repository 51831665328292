import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Select from "react-select";
import Moment from "moment";
import { getToken } from "../../utils/Common";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import { Image, Transformation } from "cloudinary-react";
import _ from "lodash";

function RegisterItem() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;
  const [hotelList, setHotelList] = useState([]);
  const [itemHotel, setItemHotel] = useState([]);
  const [itemUnit, setItemUnit] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [IMGUpload, setIMGUpload] = useState(null);
  const [itemName, setItemName] = useState();
  const [itemDescription, setItemDescription] = useState();
  const [itemGroupSelect, setItemGroupSelect] = useState();
  const [itemUnitSelect, setItemUnitSelect] = useState();
  const [itemVolume, setItemVolume] = useState();
  const [itemLeadTime, setItemLeadTime] = useState();
  const [itemMinOrder, setItemMinOrder] = useState();
  const [registering, setRegistering] = useState(false);

  const uploadImage = (e) => {
    console.log(e);
    let file = e.target.files[0];
    setSelectedImage(URL.createObjectURL(file));

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "hflkjxlr");
    formData.append("cloud_name", "cloud-prima-hotel-indonesia");
    fetch(
      "https://api.cloudinary.com/v1_1/cloud-prima-hotel-indonesia/image/upload",
      {
        method: "post",
        body: formData,
      }
    )
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data.url);
        setIMGUpload(data.url);
      });
  };

  const handleChangeItemHotel = (e) => {
    setItemHotel(e);
  };

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotels/for/select/user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(Object.values(response.data).flatMap((arr) => arr));
      })
      .catch(function (error) {
        console.log("Error API Hotel");
      });
  }, [hcode, headers]);

  const getItemUnit = useCallback(() => {
    axios
      .get(`/api/list/item/unit`, { headers: headers })
      .then(function (response) {
        setItemUnit(response.data);
      })
      .catch(function (error) {
        console.log("Error API Item Unit");
      });
  }, [headers]);

  const getItemGroup = useCallback(() => {
    axios
      .get(`/api/list/item/group`, { headers: headers })
      .then(function (response) {
        // console.log(response.data)
        setItemGroup(response.data);
      })
      .catch(function (error) {
        console.log("Error API Item Unit");
      });
  }, [headers]);

  const submitItem = async () => {
    setRegistering(true);
    try {
      if (
        !itemName ||
        !itemUnit ||
        !itemGroupSelect ||
        !itemLeadTime ||
        !itemVolume ||
        !itemUnitSelect ||
        !itemMinOrder ||
        !IMGUpload ||
        !itemHotel
      ) {
        Swal.fire({
          icon: "info",
          text: "Please fill the form correctly",
        });
        return;
      }

      const result = await Swal.fire({
        title: "Do you want to register this item?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      });

      if (result.isConfirmed) {
        await axios.post(
          `/api/register/item`,
          {
            udeptID,
            itemName,
            itemUnitSelect,
            itemGroupSelect,
            itemLeadTime,
            itemDescription,
            itemVolume,
            itemMinOrder,
            IMGUpload,
            itemHotel,
          },
          { headers }
        );
        history.push("/list/item");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRegistering(false);
    }
  };

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);
  const debouncedGetItemUnit = _.debounce(getItemUnit, 100);
  const debouncedGetItemGroup = _.debounce(getItemGroup, 100);

  // useEffect to trigger the debounced functions when hcode or udeptID changes
  useEffect(() => {
    debouncedGetListHotelbyUser();
    debouncedGetItemUnit();
    debouncedGetItemGroup();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Item
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Item
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Register
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row pt-3">
                    <div class="col-md-6 col-sm-12">
                      <label htmlFor="itemName" class="form-label">
                        Item Name
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="itemName"
                        onChange={(e) => setItemName(e.target.value)}
                      ></input>
                    </div>

                    <div class="col-md-3 col-sm-12">
                      <label htmlFor="itemImage" class="form-label">
                        Item Image
                      </label>
                      <input
                        type="file"
                        class="form-control form-control-sm"
                        onChange={(e) => uploadImage(e)}
                        id="itemImage"
                      ></input>
                    </div>

                    <div class="col-md-3 col-sm-12">
                      <img className="img-fluid" src={selectedImage} />
                    </div>

                    <div class="col-md-12 mt-2">
                      <label htmlFor="itemDesc" class="form-label">
                        Item Explanation
                      </label>
                      <textarea
                        class="form-control form-control-sm"
                        id="itemDesc"
                        style={{ height: "100px" }}
                        onChange={(e) => setItemDescription(e.target.value)}
                      ></textarea>
                    </div>

                    <div class="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="unitItem" class="form-label">
                        Item Unit
                      </label>
                      <select
                        class="form-control form-control-sm"
                        style={{ cursor: "pointer" }}
                        id="unitItem"
                        onChange={(e) => setItemUnitSelect(e.target.value)}
                      >
                        <option>Item Unit</option>
                        {itemUnit.map((x, t) => (
                          <option key={t} value={x.measurement}>
                            {x.measurement}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="col-md-3 col-sm-12 mt-2">
                      <label htmlFor="unitItem" class="form-label">
                        Item Group
                      </label>
                      <select
                        class="form-control form-control-sm"
                        style={{ cursor: "pointer" }}
                        id="unitItem"
                        onChange={(e) => setItemGroupSelect(e.target.value)}
                      >
                        <option>Item Group</option>

                        {itemGroup.map((x, t) => (
                          <option key={t} value={x.category}>
                            {x.category}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="itemTime" class="form-label">
                        Estimate lead Time
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="itemTime"
                        onChange={(e) => setItemLeadTime(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="itemVolume" class="form-label">
                        Item Volume
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="itemVolume"
                        onChange={(e) => setItemVolume(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-2 col-sm-12 mt-2">
                      <label htmlFor="minOrder" class="form-label">
                        Min Order
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="minOrder"
                        onChange={(e) => setItemMinOrder(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      ></input>
                    </div>

                    <div class="col-md-12 col-sm-12 mt-2 mb-2">
                      <label htmlFor="forHotel" class="form-label">
                        For Hotel
                      </label>
                      <Select
                        placeholder={"Select Hotel"}
                        id="forHotel"
                        isMulti={true}
                        onChange={(e) => handleChangeItemHotel(e)}
                        options={hotelList}
                      />
                    </div>

                    {itemName && itemUnit && itemHotel && (
                      <div className="p-3">
                        <button
                          className="btn btn-warning mb-5"
                          style={{ fontSize: "12px" }}
                          onClick={() => submitItem()}
                        >
                          {registering ? "Registering.." : "Register"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default RegisterItem;
