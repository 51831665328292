import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/dashboard" ? "active" : ""
            }`}
            onClick={() => history.push("/dashboard")}
          >
            <a
              href="#"
              className={`nav-link nav-item-custom2 ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <i
                className={`bi bi-grid activegold nav-item-custom2 ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
              ></i>
              <span>Dashboard</span>
            </a>
          </li>

          {/* <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/create/proposal" ? "active" : ""
            }`}
          >
            <a
              className={`nav-link nav-item-custom2 ${
                location.pathname === "/create/proposal" ? "active" : ""
              }`}
              onClick={() => history.push("/create/proposal")}
            >
              <i
                className={`bi bi-pencil activegold nav-item-custom2 ${
                  location.pathname === "/create/proposal" ? "active" : ""
                }`}
              ></i>
              <span>Create New</span>
            </a>
          </li> */}

          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/waiting/proposal" ||
              location.pathname === "/reject/proposal" ||
              location.pathname === "/item/reject/proposal"
                ? "active"
                : ""
            }`}
          >
            <a
              className={`nav-link collapse nav-item-custom2 ${
                location.pathname === "/waiting/proposal" ||
                location.pathname === "/reject/proposal" ||
                location.pathname === "/item/reject/proposal"
                  ? "active"
                  : ""
              }`}
              data-bs-target="#components-nav-pp"
              data-bs-toggle="collapse"
            >
              <i
                className={`bi bi-card-list activegold ${
                  location.pathname === "/waiting/proposal" ||
                  location.pathname === "/reject/proposal" ||
                  location.pathname === "/item/reject/proposal"
                    ? "active"
                    : ""
                }`}
              ></i>
              <span>Purchase Proposal</span>
              <i
                className={`bi bi-chevron-down ms-auto activegold ${
                  location.pathname === "/waiting/proposal" ||
                  location.pathname === "/reject/proposal" ||
                  location.pathname === "/item/reject/proposal"
                    ? "active"
                    : ""
                }`}
              ></i>
            </a>
            <ul
              id="components-nav-pp"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li onClick={() => history.push("/waiting/proposal")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/waiting/proposal" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/waiting/proposal" ? "active" : ""
                    }`}
                  >
                    Waiting
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/reject/proposal")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/reject/proposal" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/reject/proposal" ? "active" : ""
                    }`}
                  >
                    Reject
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/item/reject/proposal")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/item/reject/proposal"
                        ? "active"
                        : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/item/reject/proposal"
                        ? "active"
                        : ""
                    }`}
                  >
                    Item Reject
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/waiting/request" ||
              location.pathname === "/reject/request"
                ? "active"
                : ""
            }`}
          >
            <a
              className={`nav-link collapse nav-item-custom2 ${
                location.pathname === "/waiting/request" ||
                location.pathname === "/reject/request"
                  ? "active"
                  : ""
              }`}
              data-bs-target="#components-nav-pr"
              data-bs-toggle="collapse"
            >
              <i
                className={`bi bi-card-checklist activegold ${
                  location.pathname === "/waiting/request" ||
                  location.pathname === "/reject/request"
                    ? "active"
                    : ""
                }`}
              ></i>
              <span>Purchase Request</span>
              <i
                className={`bi bi-chevron-down ms-auto activegold ${
                  location.pathname === "/waiting/request" ||
                  location.pathname === "/reject/request"
                    ? "active"
                    : ""
                }`}
              ></i>
            </a>
            <ul
              id="components-nav-pr"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li onClick={() => history.push("/waiting/request")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/waiting/request" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/waiting/request" ? "active" : ""
                    }`}
                  >
                    Waiting
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/reject/request")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/reject/request" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/reject/request" ? "active" : ""
                    }`}
                  >
                    Rejected
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/purchase/order" ||
              location.pathname === "/receive/item"
                ? "active"
                : ""
            }`}
          >
            <a
              className={`nav-link collapse nav-item-custom2 ${
                location.pathname === "/purchase/order" ||
                location.pathname === "/receive/item"
                  ? "active"
                  : ""
              }`}
              data-bs-target="#components-nav-po"
              data-bs-toggle="collapse"
            >
              <i
                className={`bi bi-card-heading activegold ${
                  location.pathname === "/purchase/order" ||
                  location.pathname === "/receive/item"
                    ? "active"
                    : ""
                }`}
              ></i>
              <span>Purchase Order</span>
              <i
                className={`bi bi-chevron-down ms-auto activegold ${
                  location.pathname === "/purchase/order" ||
                  location.pathname === "/receive/item"
                    ? "active"
                    : ""
                }`}
              ></i>
            </a>
            <ul
              id="components-nav-po"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              {/* <li onClick={() => history.push("/purchase/order")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/purchase/order" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/purchase/order" ? "active" : ""
                    }`}
                  >
                    Generated
                  </span>
                </a>
              </li> */}
              <li onClick={() => history.push("/purchase/order/generated")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/purchase/order/generated"
                        ? "active"
                        : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/purchase/order/generated"
                        ? "active"
                        : ""
                    }`}
                  >
                    Generated
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/purchase/order")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/purchase/order" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/purchase/order" ? "active" : ""
                    }`}
                  >
                    Sent
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/receive/item")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/receive/item" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/receive/item" ? "active" : ""
                    }`}
                  >
                    Received
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/add/item" ||
              location.pathname === "/list/item"
                ? "active"
                : ""
            }`}
          >
            <a
              className={`nav-link collapse nav-item-custom2 ${
                location.pathname === "/add/item" ||
                location.pathname === "/list/item"
                  ? "active"
                  : ""
              }`}
              data-bs-target="#components-nav-item"
              data-bs-toggle="collapse"
            >
              <i
                className={`bi bi-card-heading activegold ${
                  location.pathname === "/add/item" ||
                  location.pathname === "/list/item"
                    ? "active"
                    : ""
                }`}
              ></i>
              <span>Item</span>
              <i
                className={`bi bi-chevron-down ms-auto activegold ${
                  location.pathname === "/add/item" ||
                  location.pathname === "/list/item"
                    ? "active"
                    : ""
                }`}
              ></i>
            </a>
            <ul
              id="components-nav-item"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li onClick={() => history.push("/add/item")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/add/item" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/add/item" ? "active" : ""
                    }`}
                  >
                    Register
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/list/item")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/list/item" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/list/item" ? "active" : ""
                    }`}
                  >
                    List
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/list/item/waiting")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/list/item/waiting" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/list/item/waiting" ? "active" : ""
                    }`}
                  >
                    Waiting
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/list/supplier")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/list/supplier" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/list/supplier" ? "active" : ""
                    }`}
                  >
                    Supplier
                  </span>
                </a>
              </li>
            </ul>
          </li>

          <li
            className={`nav-item nav-item-custom2 ${
              location.pathname === "/pp/report" ||
              location.pathname === "/po/report" ||
              location.pathname === "/item/receive/report" ||
              location.pathname === "/item/report"
                ? "active"
                : ""
            }`}
          >
            <a
              className={`nav-link collapse nav-item-custom2 ${
                location.pathname === "/pp/report" ||
                location.pathname === "/po/report" ||
                location.pathname === "/item/receive/report" ||
                location.pathname === "/item/report"
                  ? "active"
                  : ""
              }`}
              data-bs-target="#components-nav-report"
              data-bs-toggle="collapse"
            >
              <i
                className={`bi bi-card-heading activegold ${
                  location.pathname === "/pp/report" ||
                  location.pathname === "/po/report" ||
                  location.pathname === "/item/receive/report" ||
                  location.pathname === "/item/report"
                    ? "active"
                    : ""
                }`}
              ></i>
              <span>Report</span>
              <i
                className={`bi bi-chevron-down ms-auto activegold ${
                  location.pathname === "/pp/report" ||
                  location.pathname === "/po/report" ||
                  location.pathname === "/item/receive/report" ||
                  location.pathname === "/item/report"
                    ? "active"
                    : ""
                }`}
              ></i>
            </a>
            <ul
              id="components-nav-report"
              className="nav-content collapse"
              data-bs-parent="#sidebar-nav"
            >
              <li onClick={() => history.push("/pp/report")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/pp/report" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/pp/report" ? "active" : ""
                    }`}
                  >
                    Purchase Request
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/po/report")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/po/report" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/po/report" ? "active" : ""
                    }`}
                  >
                    Purchase Order
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/item/receive/report")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/item/receive/report"
                        ? "active"
                        : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/item/receive/report"
                        ? "active"
                        : ""
                    }`}
                  >
                    Item Receive
                  </span>
                </a>
              </li>
              <li onClick={() => history.push("/item/report")}>
                <a className="nav-link">
                  <i
                    className={`bi bi-circle activegold ${
                      location.pathname === "/item/report" ? "active" : ""
                    }`}
                  ></i>
                  <span
                    className={`hoverwhite activegold ${
                      location.pathname === "/item/report" ? "active" : ""
                    }`}
                  >
                    Item's
                  </span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Navbar;
