import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

import LoadingOverlay from "react-loading-overlay-ts";
import Moment from "moment";

function ItemReceive() {
  const FileDownload = require("js-file-download");
  // Var
  const [detailUser, setDetailUser] = useState([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  const numberFormat = (value) =>
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumSignificantDigits: 5,
    }).format(value);

  // Session
  const session = localStorage.getItem("user");
  const uID = JSON.parse(session).id;
  const hcode = JSON.parse(session).hotel_id;
  const role = JSON.parse(session).role_name;
  const udeptID = JSON.parse(session).dept_id;
  const [reportPP, setReportPP] = useState([]);
  const [hotellabelChart, setHotelLabelChart] = useState([]);
  const [btnPrint, setBtnPrint] = useState(false);

  const [groupItem, setGroupItem] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [listReport, setListReport] = useState([]);
  const [startDate, setStartDate] = useState();
  const [untilDate, setUntilDate] = useState();
  const [hotelReport, setHotelReport] = useState();
  const [category, setCategory] = useState();
  const [department, setDepartment] = useState();
  const [totalQuotation, setTotalQuotation] = useState();

  const generateReport = () => {
    if (!startDate || !untilDate || !hotelReport || !category || !department) {
      Swal.fire({
        position: "bottom-start",
        icon: "info",
        title: "Please complete the search form",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      //Process Search
      axios
        .post(
          `/api/generate/report/by/spend`,
          {
            startDate,
            untilDate,
            category,
            department,
            hotelReport,
          },
          { headers: headers }
        )
        .then(function (response) {
          console.log(response.data);
          setListReport(response.data[0]);
          setTotalQuotation(response.data[1]);
          if (response.data.length > 0) {
            setBtnPrint(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getLisGroupUnit = () => {
    axios
      .get(`/api/list/group/unit`, { headers: headers })
      .then(function (response) {
        setGroupItem(response.data[1]);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const getLisDepartment = () => {
    axios
      .get(`/api/list/departments`, { headers: headers })
      .then(function (response) {
        setDepartmentList(response.data);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const getListHotelbyUser = async () => {
    try {
      const response = await axios.post(
        `/api/list/hotel/by-user`,
        { hcode },
        { headers: headers }
      );
      setHotelList(response.data);
      setHotelLabelChart(Object.values(response.data).flatMap((arr) => arr));
      // console.log(Object.values(response.data).flatMap(arr => arr))
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);
  const debouncedGetLisGroupUnit = _.debounce(getLisGroupUnit, 200);
  const debouncedGetLisDepartment = _.debounce(getLisDepartment, 200);

  // useEffect with debounced functions
  useEffect(() => {
    debouncedGetListHotelbyUser();
    debouncedGetLisGroupUnit();
    debouncedGetLisDepartment();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={loadingActive}
        spinner
        text="Loading your content..."
      >
        <HEAD />
        <SIDE />

        <main id="main" className="main">
          <div className="pagetitle">
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", marginLeft: "7px" }}
            >
              Report
            </h6>
            <nav>
              <ol className="breadcrumb mt-1 mb-2 ms-2">
                <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                  Receive Item
                </li>
                <li
                  style={{ fontSize: "13px" }}
                  className="breadcrumb-item active"
                >
                  Report
                </li>
              </ol>
            </nav>
          </div>

          <section>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-12">
                <div className="card">
                  <div className="card-body ps-4 pt-3">
                    <h5 class="card-title">Total Spend </h5>
                    <div className="row">
                      <div className="col-2">
                        <label>Start</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="startDate"
                          placeholder="Choose Date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>

                      <div className="col-2">
                        <label>End</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="untilDate"
                          onChange={(e) => setUntilDate(e.target.value)}
                        />
                      </div>

                      <div className="col-2">
                        <label>For Hotel</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setHotelReport(e.target.value)}
                        >
                          <option selected="">Hotel</option>
                          {hotelList.map((x, t) =>
                            x.map((v, k) => (
                              <option key={k} value={v.id_hotel}>
                                {v.code_hotel} - {v.nm_hotel}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="col-3">
                        <label>Category</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">All Category</option>
                          {groupItem.map((x, t) => (
                            <option key={t} value={x.category}>
                              {x.category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-3">
                        <label>Department</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <option>Select Department</option>
                          {departmentList.map((x, t) => (
                            <option key={t} value={x.id_department}>
                              {x.nm_department}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-12 mt-3">
                        <button
                          type="button"
                          className="btn btn-warning"
                          style={{ marginRight: "10px", fontSize: "12px" }}
                          onClick={() => generateReport()}
                        >
                          Search
                        </button>
                        {btnPrint && (
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ fontSize: "12px" }}
                          >
                            Download Excel
                          </button>
                        )}
                      </div>
                    </div>
                    {/* Search Result */}
                    <div className="row">
                      <div className="col-12">
                        {listReport.length > 0 ? (
                          <table class="table table-bordered mt-5">
                            <thead>
                              <tr>
                                <th scope="col">PP.No</th>
                                <th scope="col">Remark</th>
                                <th scope="col">Item</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price</th>
                                <th scope="col">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {listReport?.map((v, k) => (
                                <tr>
                                  <td>{v.nomer}</td>
                                  <td>{v.remark}</td>
                                  <td>{v.nama_item}</td>
                                  <td>
                                    {v.quantity} {v.unit}
                                  </td>
                                  <td>{numberFormat(v.quotation_price)}</td>
                                  <td>{numberFormat(v.quotation_total)}</td>
                                </tr>
                              ))}
                              <tr style={{ fontSize: "18px" }}>
                                <td
                                  colSpan={5}
                                  style={{ fontWeight: "bolder" }}
                                >
                                  Total
                                </td>
                                <td>{numberFormat(totalQuotation)}</td>
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginBottom: "5%" }}
                          >
                            No Data
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </LoadingOverlay>
    </>
  );
}

export default ItemReceive;
