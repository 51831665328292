import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Login Auth
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import { getToken } from "./utils/Common";
import Login from "./page/login";
import Dashboard from "./page/dashboard";

import CreateProposal from "./page/proposal/create";
import Proposal from "./page/proposal";
import Details from "./page/proposal/detail";
import ApproveProposal from "./page/proposal/approve";
import RejectProposal from "./page/proposal/reject";
import ItemRejectProposal from "./page/proposal/item_reject";

import Request from "./page/request";
import RequestDaily from "./page/request/daily";
import RequestOutStanding from "./page/request/outstanding";

import RejectRequest from "./page/request/reject";

import Items from "./page/items";
import ItemsWaiting from "./page/items/waiting";

import AddItem from "./page/items/add";
import DetailItem from "./page/items/edit";
import ListGroup from "./page/items/group_unit";
import PurchaseOrder from "./page/po";
import PurchaseOrderGenerated from "./page/po/pogenerated";
import PurchaseOrderDaily from "./page/po/daily";
import PurchaseOrderOutStanding from "./page/po/outstanding";

import Account from "./page/dashboard/account";

import PPREPORT from "./page/reports/pp";
import PRREPORT from "./page/reports/pr";
import POREPORT from "./page/reports/po";
import ITEMREPORT from "./page/reports/items";

import SUPPLIER from "./page/supplier";
import ADDSUPPLIER from "./page/supplier/add";
import DETAILSUPPLIER from "./page/supplier/edit";
import ReceiveItem from "./page/po/received";

import RIREPORT from "./page/reports/receive";

export default function App() {
  const token = getToken();
  useEffect(() => {
    if (!token) {
      return;
    }
    console.log("Token is detected");
  }, [token]);

  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/create/proposal" component={CreateProposal} />

      <PrivateRoute exact path="/detail/proposal/:IDPP" component={Details} />

      <PrivateRoute exact path="/waiting/proposal" component={Proposal} />
      <PrivateRoute
        exact
        path="/approve/proposal"
        component={ApproveProposal}
      />
      <PrivateRoute exact path="/reject/proposal" component={RejectProposal} />
      <PrivateRoute
        exact
        path="/item/reject/proposal"
        component={ItemRejectProposal}
      />

      <PrivateRoute
        exact
        path="/waiting/request/daily/:HOTELGROUP"
        component={RequestDaily}
      />
      <PrivateRoute
        exact
        path="/waiting/request/outstanding/:HOTELGROUP"
        component={RequestOutStanding}
      />
      <PrivateRoute exact path="/waiting/request" component={Request} />
      <PrivateRoute exact path="/reject/request" component={RejectRequest} />

      <PrivateRoute exact path="/purchase/order" component={PurchaseOrder} />
      <PrivateRoute
        exact
        path="/purchase/order/generated"
        component={PurchaseOrderGenerated}
      />
      <PrivateRoute
        exact
        path="/purchase/order/daily/:HOTELGROUP"
        component={PurchaseOrderDaily}
      />
      <PrivateRoute
        exact
        path="/purchase/order/outstanding/:HOTELGROUP"
        component={PurchaseOrderOutStanding}
      />

      <PrivateRoute exact path="/receive/item" component={ReceiveItem} />
      <PrivateRoute exact path="/list/item" component={Items} />
      <PrivateRoute exact path="/list/group/unit" component={ListGroup} />
      <PrivateRoute exact path="/list/item/waiting" component={ItemsWaiting} />
      <PrivateRoute exact path="/add/item" component={AddItem} />
      <PrivateRoute exact path="/detail/item/:IDITEM" component={DetailItem} />

      <PrivateRoute exact path="/account/setting" component={Account} />

      {/* Reports */}
      <PrivateRoute exact path="/pp/report" component={PPREPORT} />
      <PrivateRoute exact path="/pr/report" component={PRREPORT} />
      <PrivateRoute exact path="/po/report" component={POREPORT} />
      <PrivateRoute exact path="/item/receive/report" component={RIREPORT} />
      <PrivateRoute exact path="/item/report" component={ITEMREPORT} />

      {/* Supplier */}
      <PrivateRoute exact path="/list/supplier" component={SUPPLIER} />
      <PrivateRoute exact path="/add/supplier" component={ADDSUPPLIER} />
      <PrivateRoute
        exact
        path="/detail/supplier/:IDSUPP"
        component={DETAILSUPPLIER}
      />
    </Switch>
  );
}
