import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { setUserSession } from "../../utils/Common";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import DataTable from "react-data-table-component";
import Moment from "moment";
import { Image, Transformation } from "cloudinary-react";
import { Modal, ModalFooter } from "react-bootstrap";
import _ from "lodash";

function ReceivedItem() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;
  // Var
  const [receiveItem, setReceiveItem] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log("Error API Hotel: ", error);
      });
  }, [hcode, headers]);

  const getListReceiveItem = async (hotelSort) => {
    setLoading(true);
    try {
      let response;
      if (hotelSort) {
        const hcode = hotelSort;
        response = await axios.post(
          "/api/receive/items",
          { hcode, udeptID },
          { headers: headers }
        );
      } else {
        response = await axios.post(
          "/api/receive/items",
          { hcode, udeptID },
          { headers: headers }
        );
      }
      console.log(response.data);
      setReceiveItem(Object.values(response.data).flatMap((arr) => arr));
    } catch (error) {
      console.log("Error getListReceiveItem: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = receiveItem.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );
  const [openModalImg, setOpenModalImg] = useState(false);
  const [detailPO, setDetailPO] = useState([]);

  const downloadReceiveItem = () => {
    axios
      .get(
        `/api/generate/excel/report/receive/item/${hcode}`,
        { responseType: "blob" },
        { headers: headers }
      )
      .then(function (response) {
        console.log(response.data);
        FileDownload(response.data, "receive_item.xlsx");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const openImage = (IDPO) => {
    // setImgItem(url)

    axios
      .get(`/api/detail/receive/item/${IDPO}`, { headers: headers })
      .then(function (response) {
        console.log(response.data);
        setDetailPO(response.data);
        setOpenModalImg(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const columns = [
    {
      name: "Receive Image",
      cell: (row) => (
        <Image
          style={{ width: "80px" }}
          cloudName="cloud-prima-hotel-indonesia"
          publicId={row.receive_image}
          onClick={() => openImage(row.id_po)}
        >
          <Transformation overlay="phi_png" />
          <Transformation width="500" crop="scale" />
          <Transformation opacity="20" />
          <Transformation flags="layer_apply" gravity="center" />
        </Image>
      ),
    },
    {
      name: "PR No.",
      width: "200px",

      selector: (row) => row.nomer,
    },
    {
      name: "Item",
      wrap: true,
      cell: (row) => (
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "#580C30",
          }}
        >
          {row.nama_item}
        </div>
      ),
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },
    {
      name: "Supplier.",
      wrap: true,
      selector: (row) => row.companyname,
    },
    {
      name: "Need",
      cell: (row) => Moment(row.date_need).format("LLL"),
    },
    {
      name: "PO Send",
      cell: (row) => Moment(row.posent).format("LLL"),
    },
    {
      name: "PO File",
      width: "120px",
      cell: (row) => (
        <div>
          {row.file_po && (
            <button
              className="btn btn-dark"
              onClick={() => window.open(row.file_po, "_blank")}
            >
              <i class="bi bi-file-earmark-pdf-fill"></i>
            </button>
          )}
        </div>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <>
          <div
            style={{ marginRight: "12px" }}
            onClick={() => history.push(`/detail/proposal/${row.id_pp}`)}
          >
            <i style={{ cursor: "pointer" }} class="bi bi-search"></i>
          </div>

          {/* <div  onClick={()=>history.push(`/detail/proposal/${row.id_pp}`)}>
        <i class="bi bi-chat-left"></i>
      </div>  */}
        </>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "13px",
      },
    },
    cells: {
      style: {
        fontSize: "11px",
      },
    },
  };

  const debouncedGetListReceiveItem = _.debounce(getListReceiveItem, 100);
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);

  useEffect(() => {
    debouncedGetListReceiveItem();
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" class="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Item Recieve
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Recieve
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                List
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-0 pb-2 mb-2 mt-3">
                    <div className="col-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "12px" }}
                        onClick={() => debouncedGetListReceiveItem()}
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "1%" }}
                        onClick={() => downloadReceiveItem()}
                      >
                        <i className="bi bi-printer"></i>
                      </button>
                    </div>

                    <div className="col-7 ps-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>

                    <div className="col-4">
                      <select
                        id="hotelSelect"
                        className="form-select form-select-sm"
                        onChange={(e) =>
                          debouncedGetListReceiveItem(e.target.value)
                        }
                      >
                        <option>Group By Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                  {loading ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div style={{ marginTop: "10px" }}>Loading...</div>
                    </div>
                  ) : (
                    <div className="row">
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="440px"
                        pagination
                      />
                    </div>
                  )}

                  {/* Modal Show Image Item */}
                  <Modal
                    show={openModalImg}
                    onHide={() => setOpenModalImg(false)}
                  >
                    <Modal.Body>
                      <div class="row mb-5">
                        <div class="col-lg-12 col-md-12 text-center">
                          <Image
                            style={{ width: "400px", height: "380px" }}
                            cloudName="cloud-prima-hotel-indonesia"
                            publicId={detailPO.receive_image}
                          >
                            <Transformation overlay="phi_png" />
                            <Transformation width="500" crop="scale" />
                            <Transformation opacity="20" />
                            <Transformation
                              flags="layer_apply"
                              gravity="center"
                            />
                          </Image>
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label
                          htmlFor="receiveDate"
                          class="col-md-4 col-lg-3 col-form-label"
                        >
                          Receive at
                        </label>
                        <div class="col-md-8 col-lg-9">
                          <label className="col-form-label">
                            {detailPO.receive_item_date}
                          </label>
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label
                          htmlFor="receiveQty"
                          class="col-md-4 col-lg-3 col-form-label"
                        >
                          Quantity
                        </label>
                        <div class="col-md-8 col-lg-9">
                          <label className="col-form-label">
                            {detailPO.qty_receive}
                          </label>
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label
                          htmlFor="receiveQty"
                          class="col-md-4 col-lg-3 col-form-label"
                        >
                          Receive From
                        </label>
                        <div class="col-md-8 col-lg-9">
                          <label className="col-form-label">
                            {detailPO.companyname}
                          </label>
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label
                          htmlFor="receiveQty"
                          class="col-md-4 col-lg-3 col-form-label"
                        >
                          Receive By
                        </label>
                        <div class="col-md-8 col-lg-9">
                          <label className="col-form-label">
                            {detailPO.receive_by}
                          </label>
                        </div>
                      </div>

                      <div class="row mt-1">
                        <label
                          htmlFor="receiveRemark"
                          class="col-md-4 col-lg-3 col-form-label"
                        >
                          Remark
                        </label>
                        <div class="col-md-8 col-lg-9">
                          <textarea
                            name="receiveRemark"
                            type="text"
                            class="form-control"
                            disabled
                            id="receiveRemark"
                            value={detailPO.receive_remark}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ReceivedItem;
