import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { removeUserSession } from "../../utils/Common";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import LoadingOverlay from "react-loading-overlay-ts";
import banner1 from "../assets/online.gif";
import { CSVLink } from "react-csv";
import _ from "lodash";

function ItemReport() {
  // Var
  const [detailUser, setDetailUser] = useState([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  // Session
  const session = localStorage.getItem("user");
  const uID = JSON.parse(session).id;
  const hcode = JSON.parse(session).hotel_id;

  const FileDownload = require("js-file-download");
  const [categoryItem, setCategoryItem] = useState(999);
  const [departmentReport, setDepartmentReport] = useState(999);
  const [hotelReport, setHotelReport] = useState(999);

  const [btnPrint, setBtnPrint] = useState(false);
  const [reportItems, setReportItems] = useState([]);
  const [itemCategory, setItemCategory] = useState();
  const [groupItem, setGroupItem] = useState([]);

  const getLisGroupUnit = () => {
    axios
      .get(`/api/list/group/unit`, { headers: headers })
      .then(function (response) {
        setGroupItem(response.data[1]);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const [hotelList, setHotelList] = useState([]);

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [hcode, headers]);

  const [departmentList, setDepartmentList] = useState([]);

  const getLisDepartment = () => {
    axios
      .get(`/api/list/departments`, { headers: headers })
      .then(function (response) {
        setDepartmentList(response.data);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const generateReport = () => {
    if (!categoryItem || !departmentReport || !hotelReport) {
      Swal.fire({
        position: "bottom-start",
        icon: "info",
        title: "Please Complete the Search Form",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      //Process Search
      setActiveLoading(true);
      axios
        .post(
          `/api/generate/report/items`,
          {
            categoryItem,
            departmentReport,
            hotelReport,
          },
          { headers: headers }
        )
        .then(function (response) {
          console.log(response.data);
          setReportItems(response.data);
          if (response.data.length > 0) {
            setBtnPrint(true);
          }
          setActiveLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const heading = [
    { label: "SKU.No", key: "id_item" },
    { label: "Property", key: "nm_hotel" },
    { label: "Item Description", key: "nama_item" },
    { label: "Category", key: "group_item" },
    { label: "Vol", key: "vol_item" },
    { label: "Unit", key: "unit" },
    { label: "Min Order", key: "min_order" },
    { label: "Lead Time@day", key: "lead_time" },
  ];

  const csvExport = {
    data: reportItems,
    headers: heading,
    filename: "item_report.csv",
  };

  const debouncedGetLisGroupUnit = _.debounce(getLisGroupUnit, 200);
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);
  const debouncedGetLisDepartment = _.debounce(getLisDepartment, 200);

  // UseEffect with debounced functions
  useEffect(() => {
    debouncedGetLisGroupUnit();
    debouncedGetListHotelbyUser();
    debouncedGetLisDepartment();
    // console.log(token)
  }, [token]);

  return (
    <>
      <LoadingOverlay
        active={loadingActive}
        spinner
        text="Loading your content..."
      >
        <HEAD />
        <SIDE />

        <main id="main" class="main">
          <div className="pagetitle">
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", marginLeft: "7px" }}
            >
              Report
            </h6>
            <nav>
              <ol className="breadcrumb mt-1 mb-2 ms-2">
                <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                  Item
                </li>
                <li
                  style={{ fontSize: "13px" }}
                  className="breadcrumb-item active"
                >
                  Report
                </li>
              </ol>
            </nav>
          </div>
          <section>
            <div className="row" style={{ fontSize: "14px" }}>
              <div className="col-12">
                <div class="card">
                  <div class="card-body pt-3">
                    <div className="row">
                      <div className="col-4">
                        <label className="mb-1">Category</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setCategoryItem(e.target.value)}
                        >
                          <option value="999">All</option>
                          {groupItem.map((x, t) => (
                            <option key={t} value={x.category}>
                              {x.category}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-4">
                        <label className="mb-1">For Hotel</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setHotelReport(e.target.value)}
                        >
                          <option value="999">All Property</option>
                          {hotelList.map((x, t) =>
                            x.map((v, k) => (
                              <option key={k} value={v.id_hotel}>
                                {v.code_hotel} - {v.nm_hotel}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="col-4">
                        <label className="mb-1">Department</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setDepartmentReport(e.target.value)}
                        >
                          <option value="999">All Department</option>
                          {departmentList.map((x, t) => (
                            <option key={t} value={x.id_department}>
                              {x.nm_department}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="row mt-3 mb-3">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginRight: "10px", fontSize: "12px" }}
                            onClick={() => generateReport()}
                          >
                            Search
                          </button>
                          {btnPrint && (
                            <CSVLink
                              {...csvExport}
                              separator={";"}
                              style={{
                                backgroundColor: "#fcc404",
                                padding: "8px",
                                paddingRight: "12px",
                                paddingLeft: "12px",
                                borderRadius: 8,
                                fontSize: "12px",
                                color: "#000",
                              }}
                            >
                              Export to Excel
                            </CSVLink>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {reportItems.length > 0 ? (
                        <table class="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">SKU.No</th>
                              <th scope="col">Property</th>
                              <th scope="col">Item Description</th>
                              <th scope="col">Category</th>
                              <th scope="col">Vol</th>
                              <th scope="col">Unit</th>
                              <th scope="col">Min Order</th>
                              <th scope="col">Lead Time@day</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportItems?.map((v, k) => (
                              <tr key={k}>
                                <td>{v.id_item}</td>
                                <td>{v.nm_hotel}</td>
                                <td>{v.nama_item}</td>
                                <td>{v.group_item}</td>
                                <td>{v.vol_item}</td>
                                <td>{v.unit}</td>
                                <td>{v.min_order}</td>
                                <td>{v.lead_time} Day</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          style={{ textAlign: "center", marginBottom: "5%" }}
                        >
                          No Data
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </LoadingOverlay>
    </>
  );
}

export default ItemReport;
