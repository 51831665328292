import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import { getToken } from "../../utils/Common";
import _ from "lodash";

function RequestOutStanding() {
  const FileDownload = require("js-file-download");
  const history = useHistory();
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  // Session
  const session = localStorage.getItem("user");
  const params = useParams();
  const groupID = params.HOTELGROUP;

  // Var
  const [purchaseRequest, setPurchaseRequest] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const columns = [
    {
      name: "PR No.",
      width: "200px",
      wrap: true,
      selector: (row) => row.nomer,
    },
    {
      name: "Status",

      wrap: true,
      selector: (row) => row.need,
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },
    {
      name: "Remark",
      wrap: true,
      cell: (row) => (
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "#580C30",
          }}
        >
          {row.remark}
        </div>
      ),
    },
    {
      name: " Need For",
      cell: (row) => Moment(row.date_need).format("LL"),
    },
    {
      name: "Status",
      wrap: true,
      cell: (row) => (
        <>
          <i>Waiting Purchasing in Quotation</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div onClick={() => history.push(`/detail/proposal/${row.id_pp}`)}>
          <i class="bi bi-search"></i>
        </div>
      ),
    },
  ];

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = purchaseRequest.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const getPurchaseRequestDaily = useCallback(() => {
    if (groupID == 1) {
      axios
        .get(`/api/waiting/request/outstanding/group1`, { headers: headers })
        .then(function (response) {
          console.log(response.data);
          setPurchaseRequest(
            Object.values(response.data).flatMap((arr) => arr)
          );
        })
        .catch(function (error) {
          console.log("Error API Hotel: ", error);
        });
    }

    if (groupID == 2) {
      axios
        .get(`/api/waiting/request/outstanding/group2`, { headers: headers })
        .then(function (response) {
          console.log(response.data);
          setPurchaseRequest(
            Object.values(response.data).flatMap((arr) => arr)
          );
        })
        .catch(function (error) {
          console.log("Error API Hotel: ", error);
        });
    }

    if (groupID == 3) {
      axios
        .get(`/api/waiting/request/outstanding/group3`, { headers: headers })
        .then(function (response) {
          console.log(response.data);
          setPurchaseRequest(
            Object.values(response.data).flatMap((arr) => arr)
          );
        })
        .catch(function (error) {
          console.log("Error API Hotel: ", error);
        });
    }

    if (groupID == 4) {
      axios
        .get(`/api/waiting/request/outstanding/group4`, { headers: headers })
        .then(function (response) {
          console.log(response.data);
          setPurchaseRequest(
            Object.values(response.data).flatMap((arr) => arr)
          );
        })
        .catch(function (error) {
          console.log("Error API Hotel: ", error);
        });
    }
  }, [groupID, headers]);

  const debouncedGetPurchaseRequestDaily = _.debounce(
    getPurchaseRequestDaily,
    100
  );

  // Your useEffect hook
  useEffect(() => {
    // Call the debounced function
    debouncedGetPurchaseRequestDaily();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Purchase Request</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Outstanding</a>
              </li>
              <li className="breadcrumb-item ">Purchase Request</li>
              {/* <li className="breadcrumb-item active">Waiting</li> */}
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => debouncedGetPurchaseRequestDaily()}
                    >
                      <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                    >
                      <i className="bi bi-printer"></i>
                    </button>
                  </div>

                  <div className="row p-2 mb-2">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      fixedHeader
                      fixedHeaderScrollHeight="440px"
                      pagination
                      // expandableRows
                      // expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default RequestOutStanding;
