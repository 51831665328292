import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { setUserSession } from "../../utils/Common";

import LOGO from "../assets/ashley.png";
import BG2 from "../assets/login.jpg";

function Login() {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  const [onEmail, setOnEmail] = useState();
  const [onPassword, setOnPassword] = useState();
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState("password");
  const showHidePass = () => {
    if (showPassword === "password") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };

  const submitLogin = async () => {
    if (!onEmail || !onPassword) {
      Swal.fire({
        icon: "info",
        text: "Form cannot be empty!",
      });
      return; // Early return if form is empty
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "/api/login",
        { onEmail, onPassword },
        { headers: headers }
      );

      console.log(response.data);
      setLoading(false);
      if (response.data.status === 200) {
        setUserSession(response.data.token, response.data.user);
        history.push("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        text: "Email or Password wrong",
      });
    }
  };

  useEffect(() => {
    //document.getElementById("root").style.backgroundImage = `url(${BG2})`;
    //document.getElementById("root").style.backgroundColor = `#3f3f3f`;
    document.getElementById("root").style.height = "100%";
    document.getElementById("root").style.backgroundPosition = "center";
    document.getElementById("root").style.backgroundRepeat = "no-repeat";
    document.getElementById("root").style.backgroundSize = "cover";
  }, []);

  return (
    <>
      <main
        style={{
          backgroundImage: `url(${BG2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="car   d mb-3">
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="pt-1 pb-2">
                        <div className="d-flex justify-content-center ">
                          <a className="logo d-flex align-items-center w-auto">
                            <img
                              style={{ marginTop: "20%", width: "100px" }}
                              src={LOGO}
                              alt="Prima Hotel Indonesia"
                            />
                          </a>
                        </div>
                      </div>

                      <div className="row g-3">
                        <div className="col-12">
                          <label
                            style={{ fontSize: "12px" }}
                            htmlFor="email"
                            className="form-label"
                          >
                            Username
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            onChange={(e) => setOnEmail(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            Please enter your email.
                          </div>
                        </div>

                        <div className="col-12">
                          <label
                            style={{ fontSize: "12px" }}
                            htmlFor="yourPassword"
                            className="form-label"
                          >
                            Password
                          </label>
                          <input
                            type={showPassword}
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            onChange={(e) => setOnPassword(e.target.value)}
                          />
                          <div className="invalid-feedback">
                            Please enter your password!
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              value="true"
                              id="rememberMe"
                              onClick={() => showHidePass()}
                            />
                            <label
                              className="form-check-label"
                              style={{ fontSize: "12px" }}
                              htmlFor="rememberMe"
                            >
                              Show Password
                            </label>
                          </div>
                        </div>
                        <div className="col-12 ">
                          <button
                            className="btn w-100"
                            onClick={() => submitLogin()}
                            style={{
                              backgroundColor: "#575755",
                              color: "#fff",
                              border: "none",
                            }}
                          >
                            {loading ? "Loading..." : "Login"}
                          </button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">
                            {" "}
                            <span>Ashley Hotel Group</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default Login;
