import React,{useState,useEffect,useCallback} from 'react';
import { useHistory,useParams,useLocation} from "react-router-dom";
import axios from "../../utils/axios";
import Swal from 'sweetalert2'
import { removeUserSession } from '../../utils/Common';
import { getToken } from '../../utils/Common';
import HEAD from '../../component/header'
import SIDE from '../../component/navbar'
import LoadingOverlay from 'react-loading-overlay-ts';
import banner1 from '../assets/online.gif'

function PRReport() {
// Var
const FileDownload = require('js-file-download');
const [detailUser, setDetailUser] = useState([]);
const [loadingActive, setActiveLoading] = useState(false)
const token = getToken();
const headers = { Authorization: `Bearer ${token}`,'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' };
const history = useHistory();
// Session
const session = localStorage.getItem('user');
const uID = JSON.parse(session).id;
const hcode = JSON.parse(session).hotel_id;
const role = JSON.parse(session).role_name;
const udeptID = JSON.parse(session).dept_id;
const [departmentReport,setDepartmentReport] = useState()
const [btnPrint,setBtnPrint] = useState(false)
const [departmentList,setDepartmentList] = useState([]);

const [startDate,setStartDate] = useState();
const [untilDate,setUntilDate] = useState();
const [reportKind,setReportKind] = useState();
const [hotelReport,setHotelReport] = useState();
const [actionBy,setActionBy] = useState();

const [reportPR,setReportPR] = useState([]);

const numberFormat = (value) =>
new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  maximumSignificantDigits: 5,
}).format(value);

const generateReportComplete = () => {
    axios
    .post(`/api/generate/complete/report`,{hcode},{headers:headers})
    .then(function (response) {
      console.log(response.data)
      
    })
    .catch(function (error) {
        console.log(error)
    });
  
}


const generateReport = () => {

  if(!startDate || !untilDate || !reportKind || !hotelReport || !actionBy || !departmentReport){

    Swal.fire({
      position: 'bottom-start',
      icon: 'info',
      title: 'Please complete the search form',
      showConfirmButton: false,
      timer: 1500
    })
  } else 
  {
      //Process Search
      axios
      .post(`/api/generate/report/request`,{
        startDate,
        untilDate,
        actionBy,
        reportKind,
        hotelReport,
        departmentReport
      },{headers:headers})
      .then(function (response) {
        console.log(response.data)
          setReportPR(response.data)
          if(response.data.length > 0){
            setBtnPrint(true)
          }
      })
      .catch(function (error) {
          console.log(error)
      });
  }
}

const getLisDepartment = () => {
  axios
  .get(`/api/list/departments`,{headers:headers})
  .then(function (response) {
      setDepartmentList(response.data)
  })
  .catch(function (error) {
      console.log("Error getListUnitGroup: ", error);
  });
};

const downloadReport = () => {

    //Process Search
    axios
    .get(`/api/generate/excel/report/purchase/request/${startDate}/${untilDate}/${actionBy}/${reportKind}/${hotelReport}/${departmentReport}`,{headers:headers})
    .then(function (response) {
      console.log(response.data)
      FileDownload(response.data, 'report_purchase_request.xlsx');
    })
    .catch(function (error) {
        console.log(error)
    });

}

const [hotelList, setHotelList] = useState([]);
const getListHotelbyUser = useCallback (() => {
  axios
  .post(`/api/list/hotel/by-user`,{hcode},{headers:headers})
  .then(function (response) {
      setHotelList(response.data)

  })
  .catch(function (error) {
      console.log(error);
  });
},[hcode,headers]);

useEffect(() => {
  getListHotelbyUser();
  getLisDepartment();
  generateReportComplete();
}, []);

useEffect(() => {

}, []);

  return (
   <>
    <LoadingOverlay
      active={loadingActive}
      spinner
      text='Loading your content...'
    >
   <HEAD />
   <SIDE />

   <main id="main" class="main">

    <div className="pagetitle">
      <h1>Report</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a>Purcase Request</a></li>
          <li className="breadcrumb-item active">Report</li>
        </ol>
      </nav>
    </div>

    <section>

    <div className='row' style={{fontSize:"12px"}}>
            <div className='card'>
                <div className='card-body p-2'>
                <h5 class="card-title">Generate Report</h5>
                    <div className='row'>
                        <div className='col-2'>
                          <label>Start</label>
                          <input type="date" className="form-control" id="startDate" placeholder='Choose Date' onChange={(e)=>setStartDate(e.target.value)}/>
                        </div>
                        <div className='col-2'>
                          <label>End</label>
                          <input type="date" className="form-control" id="untilDate" onChange={(e)=>setUntilDate(e.target.value)}/>
                        </div>

                        <div className='col-2'>
                            <label>Kind</label>
                            <select id="inputState" className="form-select" onChange={(e)=>setReportKind(e.target.value)}>
                                <option>Report</option>
                                <option value="1">Approved by</option>
                                <option value="2">Reject by</option>
                                <option value="3">Item reject by</option>
                            </select>
                        </div>

                        <div className='col-2'>
                            <label>Action by</label>
                            <select id="inputState" className="form-select" onChange={(e)=>setActionBy(e.target.value)}>
                                <option>Action By</option>
                                <option value="999">All</option>
                                <option value="1">Purchasing Team</option>
                                <option value="2">Purchasing Manager</option>
                                <option value="3">Chief Accounting</option>
                                <option value="4">Director of Operation</option>
                                <option value="5">Managing Director</option>
                            </select>
                        </div>

                        <div className='col-2'>
                          <label>Department</label>
                          <select id="inputState" className="form-select" onChange={(e)=>setDepartmentReport(e.target.value)}>
                              <option>Department</option>
                              {departmentList.map((x, t) => (
                                  <option key={t} value={x.id_department}>
                                      {x.nm_department}
                                  </option>
                              ))} 
                          </select>
                        </div>

                        <div className='col-2'>
                            <label>For Hotel</label>
                            <select id="inputState" className="form-select" onChange={(e)=>setHotelReport(e.target.value)}>
                                <option selected="">Hotel</option>
                                {hotelList.map((x, t) => (
                                    x.map((v, k) => (
                                        <option key={k} value={v.id_hotel}>
                                            {v.code_hotel} - {v.nm_hotel}
                                        </option>
                                    ))
                                ))} 
                            </select>
                        </div>

                        <div className='row mt-3 mb-3'>
                          <div className='col-12'>
                              <button type="button" className="btn btn-primary" style={{marginRight:"10px"}} onClick={()=>generateReport()}>Search</button>
                              {btnPrint &&
                                <button type="button" className="btn btn-success" onClick={()=>downloadReport()}>Download Excel</button>
                              }
                          </div>
                        </div>
                       
                    </div>

                    


                    {/* Search Result */}
                    <div className='row'>
                      <div className='col-12'>
                                
                        {reportPR.length > 0 ? (
                          <table class="table table-bordered">
                              <thead>
                                <tr>
                                  
                                  <th scope="col">No</th>
                                  <th scope="col">Date Need</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Purchasing Team</th>
                                  <th scope="col">Purchasing Manager</th>
                                  <th scope="col">Chief Accounting</th>
                                  <th scope="col">Director of Operation</th>
                                  <th scope="col">Managing Director@</th> 

                                </tr>
                              </thead>
                              <tbody>

                              {reportPR?.map((v,k) =>
                      
                                <tr>
                                  <td>{v.nomer}</td>
                                  <td>{v.date_need}</td>
                                  <td>{v.need}</td>
                                  <td>{v.dateCreatePR}</td>
                                  <td>{v.pm_action}</td>
                                  <td>{v.prcaction}</td>
                                  <td>{v.prdoo}</td>
                                  <td>{v.ceo_action}</td>
                                </tr>
                              )}

                              
                              
                              </tbody>
                          </table>

                        ) :
                        (
                            <div style={{textAlign:"center",marginBottom:"5%"}}>No Data</div>
                        )
                        }

                      </div>
                    </div>
                </div>
            </div>
        
      </div>

    </section>

    </main>
    </LoadingOverlay>
   
   </>
  )
}

export default PRReport