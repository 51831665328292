import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { getToken } from "../../utils/Common";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Add() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  const [companyName, setCompanyName] = useState();
  const [companyType, setCompanyType] = useState();
  const [companyPhone, setCompanyPhone] = useState();
  const [companyEmail, setCompanyEmail] = useState();
  const [companyAddress, setCompanyAddress] = useState();
  const [warehouseAddress, setWarehouseAddress] = useState();
  const [manufactureAddress, setManufactureAddress] = useState();
  const [companyBussines, setCompanyBussines] = useState();
  const [productCategory, setProductCategory] = useState();
  const [goodSource, setGoodSource] = useState();
  const [companyRep, setCompanyRep] = useState();
  const [companyRepName, setCompanyRepName] = useState();
  const [telpRep, setTelpRep] = useState();
  const [companyNPWP, setCompanyNPWP] = useState();
  const [companyStatus, setCompanyStatus] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentTerm, setPaymentTerm] = useState();
  const [bankName, setBankName] = useState();
  const [bankAccountNo, setBankAccountNo] = useState();
  const [bankAccountName, setBankAccountName] = useState();
  const [bankAddress, setBankAddress] = useState();
  const [certification, setCertification] = useState();

  const AddSupplier = () => {
    if (
      !companyName ||
      !companyType ||
      !companyEmail ||
      !companyAddress ||
      !bankName ||
      !bankAccountNo ||
      !bankAccountName ||
      !companyBussines
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      Swal.fire({
        title: "Do you want to register the supplier?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Process submit
          axios
            .post(
              `/api/insert/supplier`,
              {
                companyName,
                companyType,
                companyPhone,
                companyEmail,
                companyAddress,
                warehouseAddress,
                manufactureAddress,
                companyBussines,
                productCategory,
                goodSource,
                companyRep,
                companyRepName,
                telpRep,
                companyNPWP,
                companyStatus,
                paymentMethod,
                paymentTerm,
                bankName,
                bankAccountNo,
                bankAccountName,
                bankAddress,
                certification,
              },
              { headers: headers }
            )
            .then(function (response) {
              Swal.fire({
                icon: "success",
                text: "Supplier has been registered",
              });
              history.push("/list/supplier");
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Register Supplier & Vendor
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Register
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Supplier
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-5">
                    <div class="col-md-12 mb-2">
                      <label htmlFor="companyName" class="form-label">
                        Company Name*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>

                    <div class="col-4">
                      <label htmlFor="companyType" class="form-label">
                        Company Type*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="companyType"
                        onChange={(e) => setCompanyType(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="PT">PT</option>
                        <option value="UD">UD</option>
                        <option value="CV">CV</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyPhone" class="form-label">
                        Phone*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyPhone"
                        onChange={(e) => setCompanyPhone(e.target.value)}
                        maxLength={13}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyEmail" class="form-label">
                        Email*
                      </label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="companyEmail"
                        onChange={(e) => setCompanyEmail(e.target.value)}
                      />
                    </div>

                    <div class="col-md-12 mb-2">
                      <label htmlFor="companyAddress" class="form-label">
                        Company Address*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyAddress"
                        onChange={(e) => setCompanyAddress(e.target.value)}
                      />
                    </div>

                    <div class="col-md-12 mb-2">
                      <label htmlFor="warehouseAddress" class="form-label">
                        Warehouse Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="warehouseAddress"
                        onChange={(e) => setWarehouseAddress(e.target.value)}
                      />
                    </div>

                    <div class="col-md-12 mb-5">
                      <label htmlFor="manufactureAddress" class="form-label">
                        Manufacture Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="manufactureAddress"
                        onChange={(e) => setManufactureAddress(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyBussines" class="form-label">
                        Main Bussines*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="companyBussines"
                        onChange={(e) => setCompanyBussines(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="Automotive">Automotive </option>
                        <option value="Business Support & Supplies">
                          Business Support & Supplies{" "}
                        </option>
                        <option value="Computers & Electronics">
                          Computers & Electronics{" "}
                        </option>
                        <option value="Construction & Contractors">
                          Construction & Contractors{" "}
                        </option>
                        <option value="Education">Education </option>
                        <option value="Entertainment">Entertainment </option>
                        <option value="Food & Dining">Food & Dining </option>
                        <option value="Health & Medicine">
                          Health & Medicine{" "}
                        </option>
                        <option value="Home & Garden">Home & Garden </option>
                        <option value="Legal & Financial">
                          Legal & Financial{" "}
                        </option>
                        <option value="Manufacturing, Wholesale,Distribution">
                          Manufacturing, Wholesale,Distribution{" "}
                        </option>
                        <option value="Merchants (Retail)">
                          Merchants (Retail){" "}
                        </option>
                        <option value="Miscellaneous">Miscellaneous </option>
                        <option value="Personal Care & Services">
                          Personal Care & Services{" "}
                        </option>
                        <option value="Real Estate">Real Estate </option>
                        <option value="Travel & Transportation ">
                          Travel & Transportation{" "}
                        </option>
                        <option value="etc">etc </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="productCategory" class="form-label">
                        Product Category*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="productCategory"
                        onChange={(e) => setProductCategory(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="COMM-Commodities-Agriculture,Materials,Metals,Energy,Services">
                          COMM-Commodities-Agriculture,Materials,Metals,Energy,Services
                        </option>
                        <option value="CAPI-CapitalGoods-Vehicle,Electronic,Software,Infrastructure,Furniture&Fixtures">
                          CAPI-CapitalGoods-Vehicle,Electronic,Software,Infrastructure,Furniture&Fixtures
                        </option>
                        <option value="EXPE - Experience Goods - Medicine, Travel, Legal Services, Advice, Maintenance Service">
                          EXPE - Experience Goods - Medicine, Travel, Legal
                          Services, Advice, Maintenance Service
                        </option>
                        <option value="COMM - Commodities - Agriculture, Materials, Metals, Energy, Services">
                          COMM - Commodities - Agriculture, Materials, Metals,
                          Energy, Services
                        </option>
                        <option value="FMCG - FMCG - Beverages, Dry Food, Cosmetic, Toiletries, Fresh Food, Frozen Food">
                          FMCG - FMCG - Beverages, Dry Food, Cosmetic,
                          Toiletries, Fresh Food, Frozen Food
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="goodSource" class="form-label">
                        Good Source*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="goodSource"
                        onChange={(e) => setGoodSource(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="Distributor">Distributor</option>
                        <option value="Authorized Dealer">
                          Authorized Dealer{" "}
                        </option>
                        <option value="Official Company / Original">
                          Official Company / Original{" "}
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="certification" class="form-label">
                        Certification*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="certification"
                        onChange={(e) => setCertification(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="ISO 9000 - Quality Management">
                          ISO 9000 - Quality Management
                        </option>
                        <option value="ISO 22000 - Food Management System">
                          ISO 22000 - Food Management System
                        </option>
                        <option value="ISO 45001 - Occupational Health and Safety">
                          ISO 45001 - Occupational Health and Safety
                        </option>
                        <option value="ISO / IEC 27000 - Information Security Management">
                          ISO / IEC 27000 - Information Security Management
                        </option>
                        <option value="ISO 14000 - Environmental Management">
                          ISO 14000 - Environmental Management
                        </option>
                        <option value="ISO 50001:2018 - Energy Management">
                          ISO 50001:2018 - Energy Management
                        </option>
                        <option value="ISO 45001 - Occupational Health and Safety">
                          ISO 45001 - Occupational Health and Safety
                        </option>
                        <option value="ISO 26000:2010 - Social Responsibility">
                          ISO 26000:2010 - Social Responsibility
                        </option>
                        <option value="ISO / IEC 27000 - Information Security Management">
                          ISO / IEC 27000 - Information Security Management
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyRep" class="form-label">
                        Company Rep*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="companyRep"
                        onChange={(e) => setCompanyRep(e.target.value)}
                      >
                        <option selected="">Choose</option>

                        <option value="Sales Representative">
                          Sales Representative
                        </option>
                        <option value="Customer Service Representative">
                          Customer Service Representative
                        </option>
                        <option value="Marketing Specialist">
                          Marketing Specialist
                        </option>
                        <option value="Marketing Manager">
                          Marketing Manager
                        </option>
                        <option value="Administrative Assistant">
                          Administrative Assistant
                        </option>
                        <option value="Director">Director</option>
                        <option value="Owner">Owner</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="companyRepName" class="form-label">
                        Staff *
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyRepName"
                        onChange={(e) => setCompanyRepName(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="telpRep" class="form-label">
                        Telp Rep*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="telpRep"
                        onChange={(e) => setTelpRep(e.target.value)}
                        maxLength={13}
                        onKeyDown={(e) => {
                          if (e.key.length === 1 && /\D/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="companyNPWP" class="form-label">
                        NPWP*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="companyNPWP"
                        onChange={(e) => setCompanyNPWP(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-5">
                      <label htmlFor="companyStatus" class="form-label">
                        Company Status*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="companyStatus"
                        onChange={(e) => setCompanyStatus(e.target.value)}
                      >
                        <option value="">Choose</option>
                        <option value="PKP">PKP</option>
                        <option value="NON-PKP">NON-PKP</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="paymentMethod" class="form-label">
                        Payment Method*
                      </label>
                      <select
                        class="form-select form-select-sm"
                        id="paymentMethod"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option selected="">Choose</option>
                        <option value="Cash">Cash</option>
                        <option value="Debit cards">Debit cards</option>
                        <option value="Credit Cards">Credit Cards</option>
                        <option value="Bank transfers">Bank transfers</option>
                        <option value="Direct debit">Direct debit</option>
                        <option value="Mobile payments">Mobile payments</option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="paymentTerm" class="form-label">
                        Payment Term*{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="paymentTerm"
                        onChange={(e) => setPaymentTerm(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankName" class="form-label">
                        Bank Name*
                      </label>

                      <select
                        class="form-select form-select-sm"
                        id="bankName"
                        onChange={(e) => setBankName(e.target.value)}
                      >
                        <option selected="">Choose</option>

                        <option value="Bank Mandiri">Bank Mandiri</option>
                        <option value="Bank Rakyat Indonesia (BRI)">
                          Bank Rakyat Indonesia (BRI)
                        </option>
                        <option value="Bank Central Asia (BCA)">
                          Bank Central Asia (BCA)
                        </option>
                        <option value="Bank Negara Indonesia (BNI)">
                          Bank Negara Indonesia (BNI)
                        </option>
                        <option value="Bank Tabungan Negara (BTN)">
                          Bank Tabungan Negara (BTN)
                        </option>
                        <option value="CIMB Niaga">CIMB Niaga</option>
                        <option value="Bank Syariah Indonesia (BSI)">
                          Bank Syariah Indonesia (BSI)
                        </option>
                        <option value="Permata Bank">Permata Bank</option>
                        <option value="OCBC NISP">OCBC NISP</option>
                        <option value="Panin Bank">Panin Bank</option>
                        <option value="Bank KB Bukopin">Bank KB Bukopin</option>
                        <option value="Citibank Indonesia">
                          Citibank Indonesia
                        </option>
                        <option value="Bank CIMB Niaga">Bank CIMB Niaga</option>
                        <option value="Bank Danamon">Bank Danamon</option>
                        <option value="Bank DBS Indonesia">
                          Bank DBS Indonesia
                        </option>
                        <option value="Bank HSBC Indonesia">
                          Bank HSBC Indonesia
                        </option>
                        <option value="Bank KEB Hana Indonesia">
                          Bank KEB Hana Indonesia
                        </option>
                        <option value="Bank Mayapada">Bank Mayapada</option>
                        <option value="Bank Mega">Bank Mega</option>
                        <option value="Bank MNC Internasional">
                          Bank MNC Internasional
                        </option>
                        <option value="Bank OCBC NISP">Bank OCBC NISP</option>
                        <option value="Panin Bank">Panin Bank</option>
                        <option value="Permata Bank">Permata Bank</option>
                        <option value="Bank Sinarmas">Bank Sinarmas</option>
                        <option value="Bank UOB Indonesia">
                          Bank UOB Indonesia
                        </option>
                      </select>
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankAccountNo" class="form-label">
                        Bank Account No*
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAccountNo"
                        onChange={(e) => setBankAccountNo(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankAccountName" class="form-label">
                        Bank Account Name
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAccountName"
                        onChange={(e) => setBankAccountName(e.target.value)}
                      />
                    </div>

                    <div class="col-4 mb-2">
                      <label htmlFor="bankAddress" class="form-label">
                        Bank Address
                      </label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        id="bankAddress"
                        onChange={(e) => setBankAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-footer d-flex flex-row-reverse">
                  <button
                    type="submit"
                    style={{ fontSize: "12px" }}
                    class="btn btn-warning flex-end"
                    onClick={() => AddSupplier()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Add;
