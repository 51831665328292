import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { getToken } from "../../utils/Common";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import { Image, Transformation } from "cloudinary-react";
import { Modal, ModalFooter } from "react-bootstrap";
import _ from "lodash";

function GroupUnit() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  // Var
  const [unit, setUnit] = useState();
  const [group, setGroup] = useState();

  const [unitItem, setUnitItem] = useState([]);
  const [groupItem, setGroupItem] = useState([]);

  const [detailUnit, setDetailUnit] = useState([]);
  const [detailGroup, setDetailGroup] = useState([]);

  const [openModaladdUnit, setOpenModaladdUnit] = useState(false);
  const [openModaladdGroup, setOpenModaladdGroup] = useState(false);

  const ExpandedComponent = ({ data }) => <pre>{data.title}</pre>;

  const columns = [
    {
      name: "ID",
      width: "100px",
      wrap: true,
      selector: (row) => row.id_measurement,
    },

    {
      name: "Unit",
      selector: (row) => row.measurement,
    },

    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div>
          <i
            class="bi bi-search"
            onClick={() => history.push(`/detail/item/${row.id_item}`)}
          ></i>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      name: "ID",
      width: "100px",
      wrap: true,
      selector: (row) => row.id_item_category,
    },

    {
      name: "Group (Category) ",

      selector: (row) => row.category,
    },

    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div>
          <i
            class="bi bi-search"
            onClick={() => history.push(`/detail/item/${row.id_item}`)}
          ></i>
        </div>
      ),
    },
  ];

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = unitItem.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // Search
  const [filtertext2, setFilterText2] = useState("");
  const [resetPaginationToggle2, setResetPaginationToggle2] = useState(false);

  const filteredItems2 = groupItem.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext2.toLowerCase())
  );

  const handleClear2 = () => {
    if (filtertext2) {
      setResetPaginationToggle(!resetPaginationToggle2);
      setFilterText("");
    }
  };

  const getLisGroupUnit = () => {
    axios
      .get(`/api/list/group/unit`, { headers: headers })
      .then(function (response) {
        console.log(response.data);
        setUnitItem(response.data[0]);
        setGroupItem(response.data[1]);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const submitUnit = () => {
    axios
      .post(`/api/register/item/unit`, { unit }, { headers: headers })
      .then(function (response) {
        console.log(response.data);
        setOpenModaladdUnit(false);
        if (response.data === 1) {
          Swal.fire({
            icon: "success",
            text: "Unit item has been register",
          });
          getLisGroupUnit();
        }

        Swal.fire({
          icon: "info",
          text: "Unit item already register",
        });
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const submitGroup = () => {
    axios
      .post(`/api/register/item/group`, { group }, { headers: headers })
      .then(function (response) {
        console.log(response.data);
        setOpenModaladdGroup(false);
        if (response.data === 1) {
          Swal.fire({
            icon: "success",
            text: "Group / Category Item has been register",
          });
          getLisGroupUnit();
        }

        Swal.fire({
          icon: "info",
          text: "Group item already register",
        });
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const debouncedGetLisGroupUnit = _.debounce(getLisGroupUnit, 200);

  // useEffect with debounced function
  useEffect(() => {
    debouncedGetLisGroupUnit();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Group Item & Unit</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item ">Group</li>
              <li className="breadcrumb-item active">Unit</li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => debouncedGetLisGroupUnit()}
                    >
                      <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => setOpenModaladdUnit(true)}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                  </div>

                  <div className="row p-2 mb-2">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      fixedHeader
                      fixedHeaderScrollHeight="440px"
                      pagination
                      // expandableRows
                      // expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => debouncedGetLisGroupUnit()}
                    >
                      <i className="bi bi-arrow-clockwise"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      style={{ marginRight: "1%" }}
                      onClick={() => setOpenModaladdGroup(true)}
                    >
                      <i className="bi bi-pencil"></i>
                    </button>
                  </div>

                  <div className="row p-2 mb-2">
                    <div className="col-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => setFilterText2(e.target.value)}
                        onClear={() => handleClear2}
                        filtertext={filtertext2}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <DataTable
                      columns={columns2}
                      data={filteredItems2}
                      fixedHeader
                      fixedHeaderScrollHeight="440px"
                      pagination
                      // expandableRows
                      // expandableRowsComponent={ExpandedComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Modal Add Unit Item */}
      <Modal show={openModaladdUnit} onHide={() => setOpenModaladdUnit(false)}>
        <Modal.Body>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <label htmlFor="unitName" class="form-label">
                Item Unit
              </label>
              <input
                type="text"
                class="form-control"
                onChange={(e) => setUnit(e.target.value)}
                id="unitName"
              ></input>
            </div>
            <div class="col-lg-12 col-md-12 mt-3">
              <button className="btn btn-success" onClick={() => submitUnit()}>
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Add Group Item */}
      <Modal
        show={openModaladdGroup}
        onHide={() => setOpenModaladdGroup(false)}
      >
        <Modal.Body>
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <label htmlFor="groupName" class="form-label">
                Item Group
              </label>
              <input
                type="text"
                class="form-control"
                onChange={(e) => setGroup(e.target.value)}
                id="groupName"
              ></input>
            </div>
            <div class="col-lg-12 col-md-12 mt-3">
              <button className="btn btn-success" onClick={() => submitGroup()}>
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GroupUnit;
