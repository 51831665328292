import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import { getToken } from "../../utils/Common";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";

import LoadingOverlay from "react-loading-overlay-ts";
import Moment from "moment";
import { Bar } from "react-chartjs-2";
import { CSVLink } from "react-csv";
import _ from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function PPReport() {
  // Var
  const [detailUser, setDetailUser] = useState([]);
  const [loadingActive, setActiveLoading] = useState(false);
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();

  // Session
  const session = localStorage.getItem("user");
  const uID = JSON.parse(session).id;
  const hcode = JSON.parse(session).hotel_id;
  const role = JSON.parse(session).role_name;
  const udeptID = JSON.parse(session).dept_id;
  const [reportPP, setReportPP] = useState([]);
  const [hotellabelChart, setHotelLabelChart] = useState([]);
  const [btnPrint, setBtnPrint] = useState(false);
  const [completeReport, setCompleteReport] = useState([]);
  const [data1, setData1] = useState([]);
  const [loading, setLoading] = useState(true);

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentReport, setDepartmentReport] = useState(999);

  ///////////////////////////////////////////////////////// Table Search Data ////////////////////////////////////////
  const columns = [
    {
      name: "PP No.",
      width: "200px",
      wrap: true,
      selector: (row) => row.nomer,
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },

    {
      name: "Created",
      cell: (row) => Moment(row.created_at).format("LLL"),
    },
    {
      name: " Need For",
      cell: (row) => Moment(row.date_need).format("LL"),
    },
    {
      name: "Status",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.status}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div onClick={() => history.push(`/detail/proposal/${row.id_pp}`)}>
          <i class="bi bi-search"></i>
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.stts_pp >= 1 && row.stts_pp <= 3,
      style: {
        backgroundColor: "rgba(80, 255, 71, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.stts_pp >= 4 && row.stts_pp < 8,
      style: {
        backgroundColor: "rgba(80, 0, 255, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.stts_pp >= 9 && row.stts_pp <= 11,
      style: {
        backgroundColor: "rgba(248, 148, 6, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <>
        <table className="mt-2 table table-bordered table-sm table-hover">
          <thead>
            <tr className="table-active">
              <th scope="col">Item </th>
              <th scope="col">Qty </th>
              <th scope="col">Supplier </th>
            </tr>
          </thead>
          <tbody>
            {data.details.map((v, k) => (
              <tr>
                <td>{v.item}</td>
                <td>
                  {v.qty} {v.unit}
                </td>
                <td>{v.supp || "Not Choosen Yet"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.po ? (
          <table className="table table-bordered table-sm table-hover">
            <thead>
              <tr className="table-success">
                <th scope="col">Print </th>
                <th scope="col">Sent </th>
                <th scope="col">PO File </th>
              </tr>
            </thead>
            <tbody>
              {data.po.map((v, k) => (
                <tr>
                  <td>{v.print || "Not Print yet"}</td>
                  <td>{v.$sent || "Not Sent yet"}</td>
                  <td>
                    {(
                      <a
                        href={v.filepo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    ) || "Not Yet"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };
  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = completeReport.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );
  ///////////////////////////////////////////////////////// END Table Search Data ////////////////////////////////////////

  const getLisDepartment = () => {
    axios
      .get(`/api/list/departments`, { headers: headers })
      .then(function (response) {
        setDepartmentList(response.data);
      })
      .catch(function (error) {
        console.log("Error getListUnitGroup: ", error);
      });
  };

  const generateReportComplete = async (hotel) => {
    setLoading(true);
    try {
      let response;
      if (hotel) {
        const hcode = hotel;
        response = await axios.post(
          `/api/generate/complete/report`,
          { hcode },
          { headers: headers }
        );
      } else {
        response = await axios.post(`/api/generate/complete/report`, {
          headers: headers,
        });
      }
      setCompleteReport(response.data);
    } catch (error) {
      console.log("Error getAPIListPPW: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [startDate, setStartDate] = useState();
  const [untilDate, setUntilDate] = useState();
  const [hotelReport, setHotelReport] = useState(999);

  const generateChartPP = () => {
    //Process Get Chart All Hotel by
    axios
      .post(
        `/api/generate/chart/proposal`,
        { hcode, udeptID },
        { headers: headers }
      )
      .then(function (response) {
        console.log(response.data);
        setData1(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const generateReportPP = () => {
    if (!startDate || !untilDate || !hotelReport || !departmentReport) {
      Swal.fire({
        position: "bottom-start",
        icon: "info",
        title: "Please complete the search form",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      setActiveLoading(true);
      //Process Search
      axios
        .post(
          `/api/generate/report/proposal`,
          {
            startDate,
            untilDate,
            hotelReport,
            departmentReport,
          },
          { headers: headers }
        )
        .then(function (response) {
          console.log(response.data);
          setActiveLoading(false);
          setReportPP(response.data);
          if (response.data.length > 0) {
            setBtnPrint(true);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const heading = [
    { label: "ID", key: "id_pp" },
    { label: "No.", key: "nomer" },
    { label: "Created", key: "created_at" },
    { label: "Category", key: "need" },
    { label: "Date Need", key: "date_need" },
    { label: "Remark", key: "remark" },
    { label: "Item", key: "nama_item" },
    { label: "Supplier", key: "companyname" },
    { label: "Qty", key: "quantity" },
    { label: "Price", key: "quotation_price" },
    { label: "Total", key: "quotation_total" },
    { label: "Unit", key: "unit" },
    { label: "Request", key: "nm_hotel" },
    { label: "Department", key: "nm_department" },
    { label: "Status", key: "status" },
  ];

  const csvExport = {
    data: reportPP,
    headers: heading,
    filename: "purchaseproposal_report.csv",
  };

  const [hotelList, setHotelList] = useState([]);
  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
        setHotelLabelChart(Object.values(response.data).flatMap((arr) => arr));
        // console.log(Object.values(response.data).flatMap(arr => arr))
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [hcode, headers]);

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 100);
  const debouncedGetLisDepartment = _.debounce(getLisDepartment, 100);
  const debouncedGenerateReportComplete = _.debounce(
    generateReportComplete,
    100
  );

  // useEffect hook
  useEffect(() => {
    debouncedGetListHotelbyUser();
    debouncedGetLisDepartment();
    debouncedGenerateReportComplete();
  }, []);

  return (
    <>
      <LoadingOverlay
        active={loadingActive}
        spinner
        text="Loading your content..."
      >
        <HEAD />
        <SIDE />

        <main id="main" className="main">
          <div className="pagetitle">
            <h6
              className="mb-0"
              style={{ fontWeight: "bold", marginLeft: "7px" }}
            >
              Report
            </h6>
            <nav>
              <ol className="breadcrumb mt-1 mb-2 ms-2">
                <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                  Purchase Proposal
                </li>
                <li
                  style={{ fontSize: "13px" }}
                  className="breadcrumb-item active"
                >
                  Report
                </li>
              </ol>
            </nav>
          </div>

          <section>
            <div className="row" style={{ fontSize: "12px" }}>
              <div className="col-12">
                <div className="card">
                  <div className="card-body ps-4 pt-3">
                    <h6 class="card-title">Generate Report</h6>

                    <div className="row">
                      <div className="col-3">
                        <label>Start (Created File)</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="startDate"
                          placeholder="Choose Date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="col-3">
                        <label>End</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          id="untilDate"
                          onChange={(e) => setUntilDate(e.target.value)}
                        />
                      </div>

                      <div className="col-3">
                        <label>Department</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setDepartmentReport(e.target.value)}
                        >
                          <option value="999">All Department</option>
                          {departmentList.map((x, t) => (
                            <option key={t} value={x.id_department}>
                              {x.nm_department}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-3">
                        <label>For Hotel</label>
                        <select
                          id="inputState"
                          className="form-select form-select-sm"
                          onChange={(e) => setHotelReport(e.target.value)}
                        >
                          <option value="999">All Hotel</option>
                          {hotelList.map((x, t) =>
                            x.map((v, k) => (
                              <option key={k} value={v.id_hotel}>
                                {v.code_hotel} - {v.nm_hotel}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="row mt-3 mb-3">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-warning"
                            style={{ marginRight: "10px", fontSize: "12px" }}
                            onClick={() => generateReportPP()}
                          >
                            Search
                          </button>
                          {btnPrint && (
                            <CSVLink
                              {...csvExport}
                              separator={";"}
                              style={{
                                backgroundColor: "#fcc404",
                                padding: "8px",
                                paddingRight: "12px",
                                paddingLeft: "12px",
                                borderRadius: 8,
                                fontSize: "12px",
                                color: "#000",
                              }}
                            >
                              Export to Excel
                            </CSVLink>
                          )}
                        </div>
                      </div>
                    </div>
                    {reportPP.length > 0 ? (
                      <div className="row">
                        <div className="col-12">
                          <table class="table table-responsive table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">ID </th>
                                <th scope="col">No.</th>
                                <th scope="col">Created</th>
                                <th scope="col">Need</th>
                                <th scope="col">Date Need</th>
                                <th scope="col">Remark</th>
                                <th scope="col">Item</th>
                                <th scope="col">Supplier</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Total price</th>
                                <th scope="col">Hotel</th>
                                <th scope="col">Department</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportPP?.map((v, k) => (
                                <tr>
                                  <td>{v.id_pp}</td>
                                  <td>{v.nomer}</td>
                                  <td>{v.created_at}</td>
                                  <td>{v.need}</td>
                                  <td>{v.date_need}</td>
                                  <td>{v.remark}</td>
                                  <td>{v.nama_item} </td>
                                  <td>{v.companyname} </td>
                                  <td>
                                    {v.quantity} {v.unit}
                                  </td>
                                  <td>{v.quotation_price}</td>
                                  <td>{v.quotation_total}</td>
                                  <td>{v.nm_hotel}</td>
                                  <td>{v.nm_department}</td>
                                  <td>{v.status} </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center", marginBottom: "5%" }}>
                        No Data
                      </div>
                    )}

                    {/* <div className="row">
                    <div className="row p-2 mb-2">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          onChange={(e) => setFilterText(e.target.value)}
                          onClear={() => handleClear}
                          filtertext={filtertext}
                        />
                      </div>

                      <div className="col-4">
                        <select
                          id="hotelSelect"
                          className="form-select"
                          onChange={(e) =>
                            debouncedGenerateReportComplete(e.target.value)
                          }
                        >
                          <option>Group By Hotel</option>
                          {hotelList.map((x, t) =>
                            x.map((v, k) => (
                              <option key={k} value={v.id_hotel}>
                                {v.code_hotel} - {v.nm_hotel}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>

                    {loading ? (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ minHeight: "100px" }}
                      >
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <div style={{ marginTop: "10px" }}>Loading...</div>
                      </div>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        selectableRows
                        expandableRows
                        onSelectedRowsChange={handleChange}
                        pagination
                        conditionalRowStyles={conditionalRowStyles}
                        expandableRowsComponent={ExpandedComponent}
                      />
                    )}
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </LoadingOverlay>
    </>
  );
}

export default PPReport;
